import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { getV1UsersOptions } from "../../client/@tanstack/react-query.gen";

export const getUserOptions = () =>
  queryOptions({
    ...getV1UsersOptions(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

export const useGetUser = () => {
  return useSuspenseQuery(getUserOptions());
};
