import axios, { type AxiosError } from "axios";

import type {
  AccountOverview,
  AccountProfile,
  AdminOverview,
  AssignmentInfo,
  Client,
  ClientPreviewData,
  ClientWithLeads,
  ContentSuggestionChatMessage,
  ContentSuggestionRequestBody,
  Contract,
  GuestUserDynamicProfile,
  ImageUpload,
  ImprovedResponse,
  InviteResponseBody,
  Lead,
  OrganisationOverview,
  Organization,
  OrganizationOverview,
  Profile,
  ProfileContactDetails,
  ProfileLink,
  ProfileLinkConfig,
  ProfileLinkResponseBody,
  ProfileManager,
  ProfileOverview,
  ProfilePreview,
  ProfileSettingsUpdate,
  ProfileVisit,
  ProfileVisitDisplayModel,
  ProjectPhoto,
  ResourceComment,
  ShortProfile,
  SignInResponseBody,
  UpdatedOrganization,
  Workplace,
} from "./types";
import type { UserState } from "./userReducer";
const apiBaseUrl = process.env.REACT_APP_BASE_URL
  ? String(process.env.REACT_APP_BASE_URL)
  : "https://profiletool-api.onrender.com";
const instance = axios.create({
  baseURL: apiBaseUrl,
});

//let authenticatedInstance = axios.create({ baseURL: apiBaseUrl });
let authenticatedInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: "",
  },
});
if (localStorage.getItem("profiletool-auth-token") !== null) {
  authenticatedInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("profiletool-auth-token"),
    },
  });
}

var useMock = false;
const fetchProfilesMockData = [
  {
    id: "123",
    profile_name: "Main Profile",
  },
  {
    id: "123",
    profile_name: "Nisch Profile",
  },
];

export var fetchProfileManagerProfile = (
  managerProfileId: string,
  profileId: string,
) => {
  return new Promise<AccountProfile>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/managers/${managerProfileId}/profiles/${profileId}`)
      .then((response) => {
        const profile: Profile = JSON.parse(
          JSON.stringify(response.data.profile_data),
        );
        profile.id = profileId;
        const accountProfile: AccountProfile = {
          name: response.data.name,
          profile: profile,
          didDismissWelcomeMessage: response.data.didDismissWelcomeMessage,
          profileLinkId: response.data.profileLinkId,
          email: response.data.email,
          isOwner: false,
          isManagerProfile: false,
          profilePhotoId: response.data.profilePhotoId,
          projectPhotos: [],
        };
        resolve(accountProfile);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var fetchProfileManagerOverview = (
  managerProfileId: string,
  profileId: string,
) => {
  return new Promise<AccountOverview>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/managers/${managerProfileId}/profiles/${profileId}/overview`)
      .then((response) => {
        const profileLinks: ProfileLink[] = [];
        for (let i = 0; i < response.data.profile_links.length; i++) {
          const profileLinkData = response.data.profile_links[i];
          const profileLinkConfig: ProfileLinkConfig = JSON.parse(
            JSON.stringify(profileLinkData.config),
          );
          const creator: ShortProfile = JSON.parse(
            JSON.stringify(profileLinkData.profileLinkOwner),
          );
          const profileLink: ProfileLink = {
            link: profileLinkData.link,
            config: profileLinkConfig,
            visitCount: profileLinkData.visitCount,
            totalVisitCount: profileLinkData.totalVisitCount,
            profileLinkOwner: creator,
          };
          profileLinks.push(profileLink);
        }

        const profileData: Profile = JSON.parse(
          JSON.stringify(response.data.account_profile.profile_data),
        );

        const overview: AccountOverview = {
          profileId: response.data.profileId,
          profileLinks: profileLinks,
          profile: profileData,
          guestProfileLinks: response.data.guestProfileLinks,
          isManagerProfile: false,
          profilePhotoId: response.data.account_profile.profilePhotoId,
        };
        resolve(overview);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var fetchOverview = () => {
  return new Promise<AccountOverview>((resolve, reject) => {
    authenticatedInstance
      .get("/v1/overview")
      .then((response) => {
        const profileLinks: ProfileLink[] = [];
        for (let i = 0; i < response.data.profile_links.length; i++) {
          const profileLinkData = response.data.profile_links[i];

          const profileLinkConfig: ProfileLinkConfig = JSON.parse(
            JSON.stringify(profileLinkData.config),
          );
          const creator: ShortProfile = JSON.parse(
            JSON.stringify(profileLinkData.profileLinkOwner),
          );
          const profileLink: ProfileLink = {
            link: profileLinkData.link,
            config: profileLinkConfig,
            visitCount: profileLinkData.visitCount,
            totalVisitCount: profileLinkData.totalVisitCount,
            profileLinkOwner: creator,
          };
          profileLinks.push(profileLink);
        }
        const profileData: Profile = JSON.parse(
          JSON.stringify(response.data.account_profile.profile_data),
        );

        const profileManagers: ProfileManager[] = JSON.parse(
          JSON.stringify(response.data.profileManagers),
        );

        const overview: AccountOverview = {
          profileId: response.data.profileId,
          profileLinks: profileLinks,
          profile: profileData,
          guestProfileLinks: response.data.guestProfileLinks,
          profileManagers,
          isManagerProfile: response.data.account_profile.isManagerProfile,
        };
        resolve(overview);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var fetchProfileOverview = (
  profileId: string,
  admin: boolean,
  manager: boolean,
) => {
  return new Promise<ProfileOverview>((resolve, reject) => {
    let url = `/v1/profiles/${profileId}/overview`;
    if (admin) {
      url += `?admin=true`;
    } else if (manager) {
      url += `?manager=true`;
    }

    authenticatedInstance
      .get(url)
      .then((response) => {
        const profileOverview: ProfileOverview = JSON.parse(
          JSON.stringify(response.data),
        );
        resolve(profileOverview);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var fetchProfileOverviewV2 = (profileId: string) => {
  return new Promise<ProfileOverview>((resolve, reject) => {
    const url = `/v2/profiles/${profileId}/overview`;

    authenticatedInstance
      .get(url)
      .then((response) => {
        const profileOverview: ProfileOverview = JSON.parse(
          JSON.stringify(response.data),
        );
        resolve(profileOverview);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var getProfileLinks = (profileId: string) => {
  return new Promise<ProfileLink[]>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/profiles/${profileId}/links`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var createNewProfileLink = (
  profileId: string,
  profileLinkConfig: ProfileLinkConfig,
) => {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .post(`/v1/profiles/${profileId}/links`, profileLinkConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var createProfileLinkQuery = (
  profileLinkId: string,
  messages: ContentSuggestionChatMessage[],
) => {
  let profileVisitId = localStorage.getItem(`profile-visit-${profileLinkId}`);

  let requestBody = {
    profileVisitId: profileVisitId,
    profileLinkQueryConversation: { messages },
  };

  return new Promise<ContentSuggestionRequestBody>((resolve, reject) => {
    authenticatedInstance
      .post(`/v1/p/query/profiles/${profileLinkId}`, requestBody)
      .then((response) => {
        const resp: ContentSuggestionRequestBody = response.data;
        resolve(resp);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var createProfileManagerLink = (
  managerProfileId: string,
  profileId: string,
  profileLinkConfig: ProfileLinkConfig,
) => {
  return new Promise<string>((resolve, reject) => {
    const url = `/v1/managers/${managerProfileId}/profiles/${profileId}/links`;
    authenticatedInstance
      .post(url, profileLinkConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var fetchGuestUserOverview = () => {
  return new Promise<GuestUserDynamicProfile[]>((resolve, reject) => {
    const url = `/v1/guests/${localStorage.getItem("op-guest-user-id")}/overview`;
    instance
      .get(url)
      .then((response) => {
        const guestUserProfileLinks: GuestUserDynamicProfile[] = [];
        response.data.map((jsonObj: any) => {
          const link: GuestUserDynamicProfile = JSON.parse(
            JSON.stringify(jsonObj),
          );
          guestUserProfileLinks.push(link);
        });
        resolve(response.data);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var updateNewProfileLink = (
  profileLink: string,
  profileLinkConfig: ProfileLinkConfig,
) => {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/links/${profileLink}`, profileLinkConfig)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var resetProfile = (profileId: string) => {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/profiles/${profileId}/reset`)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export function getProjectPhotos(profileId: string) {
  return new Promise<ProjectPhoto[]>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/profiles/${profileId}/projectphotos`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function createProjectPhoto(
  profileId: string,
  projectId: string,
  imageUpload: ImageUpload,
) {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .post(
        `/v1/profiles/${profileId}/projects/${projectId}/photos`,
        imageUpload,
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function removeProjectPhoto(
  profileId: string,
  projectId: string,
  assetId: string,
) {
  return new Promise<ProjectPhoto[]>((resolve, reject) => {
    authenticatedInstance
      .delete(
        `/v1/profiles/${profileId}/projects/${projectId}/photos/${assetId}`,
      )
      .then((response) => {
        const projectPhotos: ProjectPhoto[] = JSON.parse(
          JSON.stringify(response.data),
        );
        resolve(projectPhotos);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function updateProfilePhoto(
  profileId: string,
  imageUpload: ImageUpload,
) {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/profiles/${profileId}/photo`, imageUpload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export var fetchProfiles = () => {
  return new Promise<ProfilePreview[]>((resolve, reject) => {
    if (useMock) {
      const profilePreviews: ProfilePreview[] = [];
      fetchProfilesMockData.map((jsonObj) => {
        const profilePreview: ProfilePreview = JSON.parse(
          JSON.stringify(jsonObj),
        );
        profilePreviews.push(profilePreview);
      });
      resolve(profilePreviews);
      return;
    }

    authenticatedInstance
      .get("/v1/profiles")
      .then((response) => {
        const profilePreviews: ProfilePreview[] = [];
        response.data.map((jsonObj: any) => {
          const profilePreview: ProfilePreview = JSON.parse(
            JSON.stringify(jsonObj),
          );
          profilePreviews.push(profilePreview);
        });
        resolve(profilePreviews);
      })
      .catch((error) => {
        // handle error
        reject(error);
      });
  });
};

export var signUpFromTryProfile = (email: string) => {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .put("/v1/accounts/sign-up", { email: email })
      .then((response) => {
        localStorage.setItem("profiletool-auth-token", response.data.token);
        localStorage.removeItem("is-on-demo-account");
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var fetchProfileVisits = (profileId: string) => {
  return new Promise<ProfileVisitDisplayModel[]>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/profile/${profileId}/visits`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export var fetchProfile = (profileId: string) => {
  console.log("fetchProfile");

  return new Promise<AccountProfile>((resolve, reject) => {
    authenticatedInstance
      .get("/v1/profiles/" + profileId)
      .then((response) => {
        const profile: Profile = JSON.parse(
          JSON.stringify(response.data.profile_data),
        );
        profile.id = profileId;
        const accountProfile: AccountProfile = {
          name: response.data.name,
          profile: profile,
          didDismissWelcomeMessage: response.data.didDismissWelcomeMessage,
          profileLinkId: response.data.profileLinkId,
          email: "",
          isOwner: false,
          isManagerProfile: false,
          profilePhotoId: response.data.profilePhotoId,
          projectPhotos: response.data.projectPhotos,
        };

        resolve(accountProfile);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var fetchProfileAsGuest = (profileId: string) => {
  return new Promise<AccountProfile>((resolve, reject) => {
    instance
      .get(
        `/v1/guests/${localStorage.getItem("guest-user-id")}/profiles/${profileId}`,
      )
      .then((response) => {
        console.log(response.data);
        const profile: Profile = JSON.parse(
          JSON.stringify(response.data.profile_data),
        );
        profile.id = profileId;
        const accountProfile: AccountProfile = {
          name: response.data.name,
          profile: profile,
          didDismissWelcomeMessage: response.data.didDismissWelcomeMessage,
          profileLinkId: response.data.profileLinkId,
          email: "",
          isOwner: false,
          isManagerProfile: false,
          projectPhotos: [],
        };

        resolve(accountProfile);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var updateProfileFlag = (profileId: string) => {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/profiles/${profileId}/flags`)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject();
      });
  });
};

export var createGuestUser = () => {
  return new Promise<string>((resolve, reject) => {
    instance
      .post(`/v1/guests`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
      });
  });
};

export var fetchProfileFromLinkId = (profileLinkId: string) => {
  return new Promise<ProfileLinkResponseBody>((resolve, reject) => {
    const url = "/v2/p/" + profileLinkId;
    authenticatedInstance
      .get(url)
      .then((response) => {
        const profileLinkConfig: ProfileLinkConfig = JSON.parse(
          JSON.stringify(response.data.accountProfile.link_config_data),
        );

        const profile: Profile = JSON.parse(
          JSON.stringify(response.data.accountProfile.profile_data),
        );
        profile.id = response.data.id;
        const accountProfile: AccountProfile = {
          name: "My profile",
          profile: profile,
          didDismissWelcomeMessage: false,
          profileLinkId: "",
          email: "",
          linkConfig: profileLinkConfig,
          isOwner: response.data.accountProfile.isOwner,
          isCreator: response.data.accountProfile.isCreator,
          linkCreatedByProfileId:
            response.data.accountProfile.linkCreatedByProfileId,
          isManager: response.data.accountProfile.isManager,
          isManagerProfile: false,
          profilePhotoId: response.data.accountProfile.profilePhotoId,
          projectPhotos: response.data.accountProfile.projectPhotos,
        };

        const profileLinkResponseBody: ProfileLinkResponseBody = {
          accountProfile: accountProfile,
          profileContactDetails: response.data.profileContactDetails,
        };

        resolve(profileLinkResponseBody);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var removeProfileFromLinkId = (profileLinkId: string) => {
  return new Promise<void>((resolve, reject) => {
    const url = "/v1/links/" + profileLinkId;
    authenticatedInstance
      .delete(url)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var removeProfileManagerLink = (
  managerProfileId: string,
  profileId: string,
  linkCode: string,
) => {
  return new Promise<void>((resolve, reject) => {
    const url = `/v1/managers/${managerProfileId}/profiles/${profileId}/links/${linkCode}`;
    authenticatedInstance
      .delete(url)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var fetchProfileLinkSettings = (profileLinkId: string) => {
  return new Promise<AccountProfile>((resolve, reject) => {
    const url = "/v1/links/" + profileLinkId;

    authenticatedInstance
      .get(url)
      .then((response) => {
        const profileLinkConfig: ProfileLinkConfig = JSON.parse(
          JSON.stringify(response.data.link_config_data),
        );
        const profile: Profile = JSON.parse(
          JSON.stringify(response.data.profile_data),
        );
        profile.id = response.data.id;
        const accountProfile: AccountProfile = {
          name: "",
          profile: profile,
          didDismissWelcomeMessage: false,
          profileLinkId: "",
          email: response.data.email,
          linkConfig: profileLinkConfig,
          isOwner: false,
          isManagerProfile: false,
          profilePhotoId: response.data.profilePhotoId,
          projectPhotos: [],
        };
        resolve(accountProfile);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var fetchProfileLinkSettingsAsGuest = (
  managerProfileId: string,
  profileLinkId: string,
) => {
  return new Promise<AccountProfile>((resolve, reject) => {
    const url = `/v1/managers/${managerProfileId}/links/${profileLinkId}`;

    authenticatedInstance
      .get(url)
      .then((response) => {
        const profileLinkConfig: ProfileLinkConfig = JSON.parse(
          JSON.stringify(response.data.link_config_data),
        );
        const profile: Profile = JSON.parse(
          JSON.stringify(response.data.profile_data),
        );
        profile.id = response.data.id;
        const accountProfile: AccountProfile = {
          name: "",
          profile: profile,
          didDismissWelcomeMessage: false,
          profileLinkId: "",
          email: response.data.email,
          linkConfig: profileLinkConfig,
          isOwner: false,
          isManagerProfile: false,
          profilePhotoId: response.data.profilePhotoId,
          projectPhotos: [],
        };
        resolve(accountProfile);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var updateProfileVisit = (profileId: string, profileLinkId: string) => {
  return new Promise<ProfileVisit>((resolve, reject) => {
    const profileVisitId = localStorage.getItem(
      `profile-visit-${profileLinkId}`,
    );

    authenticatedInstance
      .put("/v1/visits", {
        profileId: profileId,
        profileLinkId: profileLinkId,
        profileVisitId: profileVisitId ?? "",
      })
      .then((response) => {
        localStorage.setItem(
          `profile-visit-${profileLinkId}`,
          response.data.profileVisitId,
        );
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createProfile = () => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    authenticatedInstance
      .post("/v1/profiles")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createProfileDraft = (profileId: string) => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    authenticatedInstance
      .post("/v1/profiles/draft", { profile_id: profileId })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteProfile = (profileId: string) => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    authenticatedInstance
      .delete("/v1/profiles/" + profileId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSection = (profileId: string, sectionName: string) => {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .delete(`/v1/profiles/${profileId}/sections/${sectionName}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const tryTool = () => {
  return new Promise<void>((resolve, reject) => {
    instance
      .post("/v1/profiles/try")
      .then((response) => {
        authenticatedInstance = axios.create({
          baseURL: apiBaseUrl,
          headers: {
            Authorization: response.data.token,
          },
        });
        localStorage.setItem("profiletool-auth-token", response.data.token);
        localStorage.setItem("is-on-demo-account", "true");
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const publishProfileDraft = (profileId: string) => {
  return new Promise<string>((resolve, reject) => {
    if (useMock) {
      resolve("123");
      return;
    }

    authenticatedInstance
      .put("/v1/profiles/draft", { profile_id: profileId })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateProfile = (updatedProfile: Profile) => {
  return new Promise<Profile>((resolve, reject) => {
    authenticatedInstance
      .put("/v1/profiles/" + updatedProfile.id, updatedProfile)
      .then((response) => {
        const profile: Profile = JSON.parse(JSON.stringify(response.data));
        profile.id = updatedProfile.id;
        resolve(profile);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateAccountProfile = (profileId: string, name: string) => {
  return new Promise<ProfileSettingsUpdate>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/profiles/${profileId}/settings`, { id: profileId, name: name })
      .then((response) => {
        const profileSettingsUpdate: ProfileSettingsUpdate = {
          id: response.data.id,
          name: response.data.name,
        };
        resolve(profileSettingsUpdate);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const duplicateAccountProfile = (profileId: string) => {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .post(`/v1/profiles/${profileId}/duplicate`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const signIn = (token: string) => {
  localStorage.setItem("profiletool-auth-token", token);

  authenticatedInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return new Promise<SignInResponseBody>((resolve, reject) => {
    authenticatedInstance
      .post("/v1/signin")
      .then((response) => {
        var signInResponseBody: SignInResponseBody = JSON.parse(
          JSON.stringify(response.data),
        );
        resolve(signInResponseBody);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const createAccount = (email: string) => {
  return new Promise<string>((resolve, reject) => {
    instance
      .post("/v1/accounts", { email: email })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const validateCode = (email: string, validationCode: string) => {
  return new Promise<UserState>((resolve, reject) => {
    instance
      .put("/v1/auth", { email: email, validation_code: validationCode })
      .then((response) => {
        const decodedToken = parseJwt(response.data.token);
        resolve({
          account: {
            id: decodedToken.account_id,
            email: email,
            verified: true,
          },
          isSuperAccount: decodedToken.isSuperAccount,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchAccount = () => {
  return new Promise<UserState>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/accounts`)
      .then((response) => {
        const decodedToken = parseJwt(
          localStorage.getItem("profiletool-auth-token"),
        );
        resolve({
          account: {
            id: decodedToken.account_id,
            email: response.data.email,
            verified: true,
          },
          isSuperAccount: decodedToken.isSuperAccount,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const adminFetchAllAccounts = () => {
  return new Promise<AccountProfile[]>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/admin/accounts`)
      .then((response) => {
        const accountProfiles = response.data.map((accountProfileJson: any) => {
          const profile: Profile = JSON.parse(
            JSON.stringify(accountProfileJson.profile_data),
          );
          const accountProfile: AccountProfile = {
            name: "",
            profile: profile,
            didDismissWelcomeMessage:
              accountProfileJson.didDismissWelcomeMessage,
            profileLinkId: accountProfileJson.profileLinkId,
            email: accountProfileJson.email,
            isOwner: false,
            isManagerProfile: false,
            projectPhotos: [],
          };
          return accountProfile;
        });

        resolve(accountProfiles);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateAccount = (email: string) => {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/accounts`, { email: email })
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitFeedback = (sourcePage: string, feedback: string) => {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .post(`/v1/feedback`, { sourcePage: sourcePage, feedbackText: feedback })
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWorkplaces = () => {
  return new Promise<Workplace[]>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/workplaces`)
      .then((response) => {
        const workplaces: Workplace[] = JSON.parse(
          JSON.stringify(response.data),
        );
        resolve(workplaces);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createWorkplace = (name: string) => {
  return new Promise<Workplace>((resolve, reject) => {
    authenticatedInstance
      .post(`/v1/workplaces`, { name: name })
      .then((response) => {
        const workplace: Workplace = JSON.parse(JSON.stringify(response.data));
        resolve(workplace);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchInvite = (inviteCode: string) => {
  return new Promise<InviteResponseBody>((resolve, reject) => {
    instance
      .get(`/v1/invites/${inviteCode}`)
      .then((response) => {
        resolve(JSON.parse(JSON.stringify(response.data)));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export var getProfileManagers = () => {
  return new Promise<OrganisationOverview>((resolve, reject) => {
    authenticatedInstance
      .get("/v2/manager")
      .then((response) => {
        const organisationOverview: OrganisationOverview = JSON.parse(
          JSON.stringify(response.data),
        );
        //const accountProfile: AccountProfile = { name: response.data.name, profile: profile, didDismissWelcomeMessage: response.data.didDismissWelcomeMessage, profileLinkId: response.data.profileLinkId, email: "", isOwner: false };
        resolve(organisationOverview);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export var getOrganization = (id: string) => {
  return new Promise<OrganizationOverview>((resolve, reject) => {
    authenticatedInstance
      .get("/v1/organizations/" + id)
      .then((response) => {
        resolve(JSON.parse(JSON.stringify(response.data)));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
        }
        console.log(error);
        reject();
      });
  });
};

export function acceptOrganizationInvite(inviteCode: string) {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .post("/v1/invites/" + inviteCode + "/accept")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function updateProfileManager(imageUpload: ImageUpload) {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .put("/v1/managers", imageUpload)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function updateOrganizationLogo(
  organizationId: string,
  imageUpload: ImageUpload,
  isMainLogo: boolean,
) {
  const fullUrl =
    `/v1/organizations/${organizationId}/` +
    (isMainLogo ? "logo" : "icon-logo");

  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .put(fullUrl, imageUpload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
      });
  });
}

export function updateOrganization(
  organizationId: string,
  updatedOrganization: UpdatedOrganization,
) {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/organizations/${organizationId}`, updatedOrganization)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
      });
  });
}

export function deleteProfileManager(managerProfileId: string) {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .delete(`/v1/managers/${managerProfileId}`)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function getAdminView() {
  return new Promise<AdminOverview>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/admin`)
      .then((response) => {
        const adminOverview: AdminOverview = JSON.parse(
          JSON.stringify(response.data),
        );
        resolve(adminOverview);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function getContentImprovementSuggestion(
  requestBody: ContentSuggestionRequestBody,
) {
  return new Promise<ContentSuggestionRequestBody>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/suggestions`, requestBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function getContentImprovement(
  requestBody: ContentSuggestionRequestBody,
) {
  return new Promise<ImprovedResponse>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/improve`, requestBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

function parseJwt(token: string | null) {
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    );

    return JSON.parse(jsonPayload);
  }
}

export function getCurrentOrgManagers(orgId: string) {
  return new Promise<ProfileContactDetails[]>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/orgs/${orgId}/managers`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function getClientByUrl(orgId: string, url: string): Promise<Client> {
  return new Promise<Client>((resolve, reject) => {
    return authenticatedInstance
      .get(`/v1/organisations/${orgId}/clients?url=${url}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getClients(orgId: string): Promise<Client[]> {
  return new Promise<Client[]>((resolve, reject) => {
    return authenticatedInstance
      .get(`/v1/organisations/${orgId}/clients`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createClient(orgId: string, client: Client): Promise<Client> {
  return new Promise<Client>((resolve, reject) => {
    const url = `/v1/organisations/${orgId}/clients`;

    return authenticatedInstance
      .post(url, client)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createComment(
  comment: ResourceComment,
): Promise<ResourceComment> {
  return new Promise<ResourceComment>((resolve, reject) => {
    const url = `/v1/comments`;

    return authenticatedInstance
      .post(url, comment)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetNotificationCount(resourceId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const url = `/v1/notifications/${resourceId}`;

    return authenticatedInstance
      .put(url)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateClient(orgId: string, client: Client): Promise<Client> {
  return new Promise<Client>((resolve, reject) => {
    const url = `/v1/organisations/${orgId}/clients/${client.id}`;

    return authenticatedInstance
      .put(url, client)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createLead(orgId: string, lead: Lead): Promise<Lead> {
  const url = `/v1/organisations/${orgId}/leads`;

  return new Promise<Lead>((resolve, reject) => {
    authenticatedInstance
      .post(url, lead)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateLead(
  orgId: string,
  leadId: string,
  lead: Lead,
): Promise<Lead> {
  return new Promise<Lead>((resolve, reject) => {
    authenticatedInstance
      .put(`/v1/organizations/${orgId}/leads/${leadId}`, lead)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getClientPreviewData(url: string): Promise<ClientPreviewData> {
  return new Promise<ClientPreviewData>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/company-preview?url=${url}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getLeadsByOrganizationID(
  orgId: string,
): Promise<ClientWithLeads[]> {
  return new Promise<ClientWithLeads[]>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/organizations/${orgId}/clients-leads`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getAssignmentInfo(url: string): Promise<AssignmentInfo> {
  return new Promise<AssignmentInfo>((resolve, reject) => {
    authenticatedInstance
      .post(`/v1/assignment`, { url })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getLeads(orgId: string): Promise<Lead[]> {
  return new Promise<Lead[]>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/organizations/${orgId}/leads`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function deleteComment(id: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/comments/${id}`)
      .then((response) => resolve())
      .catch((error) => reject(error));
  });
}

export function createContract(
  leadId: string,
  contract: Contract,
): Promise<Contract> {
  return new Promise<Contract>((resolve, reject) => {
    authenticatedInstance
      .post(`/v1/leads/${leadId}/contracts`, contract)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
export function getSkillInfo(skillName: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    authenticatedInstance
      .get(`/v1/skills?name=${skillName}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
