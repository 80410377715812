import * as Dialog from "@radix-ui/react-dialog";

import React, { useState, useEffect, ChangeEventHandler } from "react";
import { darkModeBlack } from "../../colors";
import Button, { ButtonStyleType } from "../atoms/Button";
import "../components.css";
import { Input, trim, LoadingSpinner } from "../../components";

import { iconForName, AvailableSkillTags } from "../TechIcons";
import { ProfileSkillTag, Workplace } from "../../types";

import {
  faRainbow,
  faCubes,
  faPenNib,
  faWandMagicSparkles,
  faOtter,
  faLaptopCode,
  faMagnifyingGlassChart,
  faChartPie,
  faCodeBranch,
  faSitemap,
  faLayerGroup,
  faPeopleArrows,
  faClipboardQuestion,
  faPersonCircleQuestion,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import EditorSkillTagButton from "../atoms/EditorSkillTagButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadButton } from "../../pages/dashboard/home/components/LeadButton";
import IconGrid, { IconItem } from "../IconGrid";
import {
  faComments,
  faHandshake,
  faObjectUngroup,
} from "@fortawesome/free-regular-svg-icons";
interface Props {
  open: boolean;
  darkModeActive: boolean;
  onBackdropClick: () => void;
  onSave: (profileSkillTags: ProfileSkillTag[]) => void;
  //selectedSkillTags: string[];
  selectedProfileSkillTags: ProfileSkillTag[];
  onCancel: () => void;
  onClose: () => void;
}

const TagsInputDialog = (props: Props) => {
  const icons: IconItem[] = [
    { name: "rainbow", icon: faRainbow },
    { name: "cubes", icon: faCubes },
    { name: "pen-nib", icon: faPenNib },
    { name: "wand-magic-sparkles", icon: faWandMagicSparkles },
    { name: "object-ungroup", icon: faObjectUngroup },
    { name: "otter", icon: faOtter },
    { name: "laptop-code", icon: faLaptopCode },
    { name: "magnifying-glass-chart", icon: faMagnifyingGlassChart },
    { name: "chart-pie", icon: faChartPie },
    { name: "code-branch", icon: faCodeBranch },
    { name: "site-map", icon: faSitemap },
    { name: "layer-group", icon: faLayerGroup },
    { name: "people-arrows", icon: faPeopleArrows },
    { name: "comments", icon: faComments },
    { name: "clipboard-question", icon: faClipboardQuestion },
    { name: "person-circle-question", icon: faPersonCircleQuestion },
    { name: "users-viewfinder", icon: faUsersViewfinder },
    { name: "handshake", icon: faHandshake },
  ];

  const [input, setInput] = useState<string>("");
  const [selectedCustomIcon, setSelectedCustomIcon] = useState<string>("");
  const [currentValue, setCurrentValue] = useState<string>("");
  const [currentMatchingSkillOptions, setCurrentMatchingSkillOptions] =
    useState<string[]>([]);
  const [selectedSkillTags, setSelectedSkillTags] = useState<string[]>([]);
  const [selectedProfileSkillTags, setSelectedProfileSkillTags] = useState<
    ProfileSkillTag[]
  >([]);
  useEffect(() => {
    //setSelectedSkillTags(props.selectedSkillTags);
    setSelectedProfileSkillTags(props.selectedProfileSkillTags);
  }, []);

  const changeToProfileSkillsTagsAndSave = (skillTags: string[]) => {};

  const addCustomSkillWithIcon = (iconName: string) => {
    const newProfileSkillTag: ProfileSkillTag = {
      name: currentValue,
      customIconName: iconName,
    };
    setSelectedProfileSkillTags([
      ...selectedProfileSkillTags,
      newProfileSkillTag,
    ]);

    props.onSave([...selectedProfileSkillTags, newProfileSkillTag]);
    setCurrentValue("");
    setCurrentMatchingSkillOptions([]);
  };

  const handleIconClick = (name: string) => {
    addCustomSkillWithIcon(name);
  };

  return (
    <div
      className="bg-op-dark absolute top-0 left-0 w-full h-full bg-opacity-50"
      onClick={() => props.onBackdropClick()}
    >
      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg max-h-[90vh] overflow-y-auto min-w-[600px] max-w-[600px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
        >
          <div>
            {selectedSkillTags.length > 0 ? (
              <h3 className="texl-lg font-semibold">
                Used in this project (Click a tag to remove it)
              </h3>
            ) : (
              <label />
            )}
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {selectedProfileSkillTags.map((item, index) => {
              return (
                <div key={index} style={{ marginRight: 15 }}>
                  <EditorSkillTagButton
                    isDarkModeActive={props.darkModeActive}
                    text={item.name}
                    profileSkillTag={item}
                    handleClick={() => {
                      const tags = [...selectedProfileSkillTags];
                      tags.splice(index, 1);
                      setSelectedProfileSkillTags(tags);
                      props.onSave(tags);
                    }}
                    keepLeftPadding
                  />
                </div>
              );
            })}
          </div>
        </div>
        <h3 className="texl-lg font-semibold mt-4">Add a new skill </h3>
        <Input
          leftIcon={faRainbow}
          label="Search for skill"
          transformValue={trim}
          value={currentValue}
          onBlur={(
            event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            const inputValue = event.target.value;
            setInput(inputValue);
          }}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            const input = event.target.value;
            const selectedSkillTags = props.selectedProfileSkillTags.map(
              (item) => {
                return item.name;
              },
            );

            const availabeSkills = AvailableSkillTags.filter(
              (s) => !selectedSkillTags?.includes(s),
            );

            var matches = availabeSkills.filter((s) =>
              s.toLowerCase().includes(input.toLowerCase()),
            );

            if (input === "") {
              setCurrentMatchingSkillOptions([]);
            } else {
              setCurrentMatchingSkillOptions(matches);
            }

            setCurrentValue(input);
          }}
        />
        <div style={{ overflowX: "scroll", marginTop: 20 }}>
          <label>Pick existing skill</label>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {currentMatchingSkillOptions.map((item, index) => {
              return (
                <div style={{ marginRight: 15 }} key={index}>
                  <EditorSkillTagButton
                    isDarkModeActive={props.darkModeActive}
                    text={item}
                    handleClick={() => {
                      const newItemString =
                        item === "Add new skill" ? input : item;
                      const newProfileSkillTag: ProfileSkillTag = {
                        name: newItemString,
                        customIconName: selectedCustomIcon,
                      };
                      setSelectedProfileSkillTags([
                        ...selectedProfileSkillTags,
                        newProfileSkillTag,
                      ]);

                      props.onSave([
                        ...selectedProfileSkillTags,
                        newProfileSkillTag,
                      ]);
                      setCurrentValue("");
                      setCurrentMatchingSkillOptions([]);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="mt-4">
            <label className="mb-4">
              or pick a symbol to create a custom skill
            </label>
            <div className="mb-2" />
            <IconGrid
              titles={["Design", "Code & Data", "Human centered"]}
              icons={icons}
              onIconClick={handleIconClick}
              itemsPerRow={6}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            text="Close"
            onButtonClick={props.onClose}
            style={ButtonStyleType.primary}
          />
        </div>
      </div>
    </div>
  );
};

export default TagsInputDialog;
