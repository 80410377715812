import Lottie from "react-lottie";
import animationData from "../../assets/loading.json";
type Size = "small" | "medium";

const S_SIZE = 96;
const M_SIZE = 144;

interface Props {
  size?: Size | number;
}

export const LoadingSpinner = ({ size = "medium" }: Props) => {
  const s = typeof size === "string" ? (size === "small" ? S_SIZE : M_SIZE) : size;

  return <Lottie options={{ animationData }} height={s} width={s} />;
};
