import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadButton, type LeadButtonProps } from "./LeadButton";
import { NumberOfUnreadEvents } from "./NumberOfUnreadEvents";

interface Props extends LeadButtonProps {
  numberOfUnreadEvents: number;
}

export const LeadEventsButton = ({ numberOfUnreadEvents, ...props }: Props) => (
  <LeadButton {...props}>
    <div className="w-9 h-9 p-2 relative shrink-0">
      <FontAwesomeIcon width={20} height={20} icon={faBell} className="w-5 h-5" />
      <NumberOfUnreadEvents numberOfUnreadEvents={numberOfUnreadEvents} />
    </div>
  </LeadButton>
);
