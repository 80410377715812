import { faComment } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useCallback, useMemo, useReducer, useRef } from "react";
import { toast } from "sonner";
import type { ResourceComment, ResourceEvent } from "../../../../../client";
import { Input, type InputRef, trim } from "../../../../../components";
import { useAnimate } from "../../../../../hooks";
import { useGetComments, useGetUser, usePostComment } from "../../../../../services";
import { LeadButton } from "../LeadButton";
import {
  LeadComment,
  LeadEvent,
  isEvent,
  isEventTypeSupported,
  isProfileLinkVisitEvent,
  isProfileLinkVisitRecurringEvent,
} from "../event";

interface Props {
  organizationId: string;
  leadId?: string;
  events: ResourceEvent[];
  onResetNumberOfUnreadEvents: () => void;
}

const isVisitEvent = (event: ResourceEvent) =>
  isProfileLinkVisitEvent(event) || isProfileLinkVisitRecurringEvent(event);

const MAX_VISIBLE_EVENTS = 4;

export const EventsPanel = ({
  organizationId,
  leadId,
  events,
  onResetNumberOfUnreadEvents,
}: Props) => {
  const [expanded, toggleExpanded] = useReducer((expanded) => !expanded, false);
  const uniqueVisitorIdsSet = new Set<string>();
  const [parent] = useAnimate();
  const inputRef = useRef<InputRef>(null);

  const postCommentToastId = useRef<string | number>();
  const { data: user } = useGetUser();
  const { data: comments } = useGetComments(leadId ?? "", { enabled: !!leadId });
  const postComment = usePostComment({
    onMutate: () => {
      postCommentToastId.current = toast.loading("Adding comment...");
    },
    onSuccess: () => {
      toast.success("Comment added", { id: postCommentToastId.current });
      onResetNumberOfUnreadEvents();
    },
    onError: () => {
      toast.error("Something went wrong", { id: postCommentToastId.current });
    },
  });

  for (const event of events) {
    if (isVisitEvent(event)) {
      uniqueVisitorIdsSet.add(event.diff.profileVisitId);
    }
  }

  const uniqueVisitorIds = Array.from(uniqueVisitorIdsSet);

  const eventsAndComments = useMemo(
    () =>
      [...events, ...(comments ?? [])]
        .filter((event): event is ResourceEvent | ResourceComment => Boolean(event))
        .filter((event) => (isEvent(event) ? isEventTypeSupported(event.eventType) : true))
        .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt))),
    [events, comments]
  );

  const handleAddComment = useCallback(
    (value: string) => {
      if (!leadId || !value) return;

      postComment.mutate({
        body: {
          comment: value,
          resourceId: leadId,
          commenterProfileId: user?.profileSummary.id ?? "",
          createdAt: dayjs().toISOString(),
          id: "",
        },
      });
      inputRef.current?.clear();
    },
    [leadId, postComment, user?.profileSummary.id]
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (inputRef.current?.value) handleAddComment(inputRef.current?.value);
    },
    [handleAddComment]
  );

  // TODO: Add loading state (suspense fallback)

  return (
    <div className="flex flex-col gap-8">
      <form onSubmit={handleSubmit}>
        <Input
          ref={inputRef}
          leftIcon={faComment}
          disabled={postComment.isPending}
          className="w-full lg:w-1/2"
          type="text"
          placeholder="Add comment"
          transformValue={trim}
          rightButton={{
            label: "Add",
            type: "submit",
            onClick: handleAddComment,
          }}
        />
      </form>

      <ul ref={parent} className="flex flex-col gap-8 md:gap-4">
        {eventsAndComments
          .slice(0, expanded ? undefined : MAX_VISIBLE_EVENTS)
          .map((event) =>
            isEvent(event) ? (
              <LeadEvent
                key={event.createdAt + event.eventType + event.triggeredByProfileId}
                organizationId={organizationId}
                visitorNumber={
                  isVisitEvent(event)
                    ? uniqueVisitorIds.indexOf(event.diff.profileVisitId) + 1
                    : undefined
                }
                {...event}
              />
            ) : (
              <LeadComment
                key={event.id || event.createdAt}
                comment={event}
                organizationId={organizationId}
              />
            )
          )}
      </ul>

      {events.length > MAX_VISIBLE_EVENTS && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 200,
            damping: 20,
          }}
          className="self-center"
        >
          <LeadButton
            className="text-sm font-bold gap-3 text-black flex flex-row items-center"
            onClick={toggleExpanded}
          >
            <FontAwesomeIcon icon={expanded ? faArrowUp : faArrowDown} />
            {expanded
              ? "show less"
              : `show more (${eventsAndComments.length - MAX_VISIBLE_EVENTS})`}
          </LeadButton>
        </motion.div>
      )}
    </div>
  );
};

EventsPanel.displayName = "EventsPanel";
