import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverTrigger } from "../../../../components";
import { LeadButton, type LeadButtonProps } from "./LeadButton";
import { LeadMenu } from "./menu";

interface Props extends LeadButtonProps {
  onArchive: () => void;
}

export const LeadMenuButton = ({ onArchive, ...props }: Props) => (
  <Popover>
    <PopoverTrigger asChild>
      <LeadButton {...props}>
        <FontAwesomeIcon width={20} height={20} icon={faEllipsis} className="w-5 h-5 shrink-0" />
      </LeadButton>
    </PopoverTrigger>
    <LeadMenu onArchive={onArchive} />
  </Popover>
);
