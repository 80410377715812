import { useParams } from "react-router-dom";
import { useGetOrganization } from "../../../../../services";
import type { Lead } from "../../../../../types";
import { MemberItem } from "./MemberItem";

interface Props {
  profileId?: string;
  onMutateLead: (lead: Partial<Lead>) => void;
}

export const MemberPanel = ({ profileId, onMutateLead }: Props) => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const { data, error } = useGetOrganization(organizationId);

  if (error || !data) throw new Error("Error fetching organization data");

  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 w-full">
      {data.profiles.map((profile) => (
        <MemberItem
          selected={profile.profileId === profileId}
          onClick={() => onMutateLead({ profileId: profile.profileId })}
          key={profile.profileId}
          {...profile}
        />
      ))}
    </div>
  );
};

MemberPanel.displayName = "MemberPanel";
