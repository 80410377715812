import type { Profile, ProfileLinkConfig } from "../../../../../client";
import { BulletSectionTitle } from "../../../../../components";
import { Workplace } from "./Workplace";

interface Props {
  profileData?: Profile;
  linkConfigData: ProfileLinkConfig;
  handleUpdateLink: (data: Partial<ProfileLinkConfig>) => void;
}

export const LinkCreatorProjects = ({ profileData, linkConfigData, handleUpdateLink }: Props) => {
  const handleCheckedChange = (checked: boolean, projectId: string) => {
    const projectsWithout =
      linkConfigData.profileLinkProjects
        ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .filter((p) => p.projectId !== projectId && !!p.projectId)
        .map(({ projectId }, index) => ({ projectId, order: index })) ?? [];

    handleUpdateLink({
      profileLinkProjects: checked
        ? [...(projectsWithout ?? []), { projectId, order: projectsWithout.length }]
        : projectsWithout,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <BulletSectionTitle>Projects</BulletSectionTitle>
      <div className="flex flex-col gap-6">
        {profileData?.side_projects_section?.side_projects.map((project) => (
          <Workplace
            key={project.id}
            checked={
              !linkConfigData.profileLinkProjects ||
              linkConfigData.profileLinkProjects.some((p) => p.projectId === project.id)
            }
            onCheckedChange={(checked) => handleCheckedChange(checked, project.id)}
            name={project.workplace?.name}
            skillTags={project.skillTags}
            title={project.title}
            description={project.pitch}
            showSkills={linkConfigData.projectsLinkConfig?.displaySkills}
            showDescription={linkConfigData.workplaceLinkConfig?.displayDescription}
          />
        ))}
      </div>
    </div>
  );
};
