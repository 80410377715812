import { motion } from "framer-motion";
import { cn } from "../utils/cn";
import type { ContainerProps } from "./types";

export const FadeInUpContainer = ({ children, className, ...props }: ContainerProps) => (
  <motion.div
    {...props}
    initial={{ opacity: 0, y: 16 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.25 }}
    className={cn(
      "mx-auto px-5 pb-safe-offset-5 md:pb-safe-offset-12 w-full max-w-screen-xl",
      className
    )}
  >
    {children}
  </motion.div>
);

FadeInUpContainer.displayName = "FadeInUpContainer";
