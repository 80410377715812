import { memo } from "react";
import type { AccountProfile, Lead, Profile, ProfileLinkConfig } from "../../../../../client";
import { useGetLink, useGetProfile, useUpdateLink } from "../../../../../services";
import { LinkCreatorPersonalBlock } from "./LinkCreatorPersonalBlock";
import { LinkCreatorProjects } from "./LinkCreatorProjects";
import { LinkCreatorSideMenu } from "./LinkCreatorSideMenu";
import { LinkCreatorWork } from "./LinkCreatorWork";

interface Props {
  lead: Lead;
  organizationId: string;
}

export const LinkCreatorDrawerContent = memo(({ lead, organizationId }: Props) => {
  const { data: profile } = useGetProfile(
    {
      organizationId,
      profileId: lead.profileId ?? "",
    },
    {
      enabled: !!lead.profileId,
    }
  );

  const { data: link } = useGetLink(lead.profileLinkId ?? "", {
    enabled: !!lead.profileLinkId,
  });

  const { mutate: updateLink, variables } = useUpdateLink();

  const handleUpdateLink = (update: Partial<ProfileLinkConfig>) => {
    if (!lead.profileLinkId) return;
    if (!link?.link_config_data) return;

    updateLink({
      path: { profileLink: lead.profileLinkId },
      body: {
        ...link.link_config_data,
        ...update,
      },
    });
  };

  // TODO: Do we want this?
  // const disabled = isPending;
  const disabled = false;

  const { link_config_data }: AccountProfile = {
    ...link,
    link_config_data: {
      ...link?.link_config_data,
      ...variables?.body,
    },
  };

  const profileData = link.profile_data as Profile;

  return (
    <div className="flex flex-col xl:flex-row gap-8 xl:gap-32 p-4 sm:px-10 sm:py-12 overflow-y-auto max-w-full">
      <div className="flex flex-col flex-1 gap-10 h-max min-w-0">
        <LinkCreatorPersonalBlock
          linkConfigData={link_config_data}
          disabled={disabled}
          handleUpdateLink={handleUpdateLink}
          profile={profile}
        />

        <LinkCreatorWork
          profileData={profileData}
          linkConfigData={link_config_data}
          handleUpdateLink={handleUpdateLink}
        />

        <LinkCreatorProjects
          profileData={profileData}
          linkConfigData={link_config_data}
          handleUpdateLink={handleUpdateLink}
        />
      </div>

      <LinkCreatorSideMenu
        disabled={disabled}
        linkConfig={link_config_data}
        onUpdate={handleUpdateLink}
      />
    </div>
  );
});
