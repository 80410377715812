import React, { useState, useEffect } from "react";
import Button, { ButtonStyleType } from "./atoms/Button";
import {
  type ProfileSkillTag,
  type SideProject,
  type Workplace,
  type ProfileComponentProps,
  ImageUpload,
  type ProjectPhoto,
  type ContentSuggestionRequestBody,
  type ProjectsLinkConfig,
} from "../types";
import { GitHub, Link, Plus, Settings, Target } from "react-feather";
import "./components.css";
import EditorTextButton from "./atoms/EditorTextButton";
import SkillTag from "./SkillTag";
import EditorSkillTagButton from "./atoms/EditorSkillTagButton";
import EditorIconButton from "./atoms/EditorIconButton";
import VkTextArea from "./legacy/VkTextArea";
import EditorTextField from "./EditorTextField";
import { Menu } from "react-feather";
import {
  createProjectPhoto,
  getContentImprovementSuggestion,
} from "../requests";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";

import ProjectPhotosRow from "./ProjectPhotosRow";

interface SideProjectEditorProps extends ProfileComponentProps {
  profileId: string;
  sideProject?: any;
  isEditable: boolean;
  isNew: boolean;
  onSaveSideProject: (sideProject: SideProject) => void;
  onCancelEdit?: () => void;
  onRemove: () => void;
  onShowDialog: (dialogType: string, projectOrder: number) => void;
  isBeingEdited: boolean;
  currentlySelectedWorkplace?: Workplace | undefined | null;
  isLastInOrder: boolean;
  onContentImprovementStarted?: () => void;
  onContentImprovementEnded?: () => void;
  projectPhotos: ProjectPhoto[];
  onProjectPhotoSelect?: (assetId: string) => void;
  onProjectPhotosUpdate?: (updatedProjectPhotos: ProjectPhoto[]) => void;
  workplaceName?: string;
  projectLinkConfig?: ProjectsLinkConfig;
  removePadding?: boolean;
}

function SideProjectEditor({
  profileId,
  isDarkModeActive,
  isEditable,
  sideProject,
  isNew,
  onSaveSideProject,
  isBeingEdited,
  onCancelEdit,
  onRemove,
  onShowDialog,
  currentlySelectedWorkplace,
  isLastInOrder,
  onContentImprovementStarted,
  onContentImprovementEnded,
  projectPhotos,
  onProjectPhotoSelect,
  onProjectPhotosUpdate,
  workplaceName,
  projectLinkConfig,
  removePadding,
}: SideProjectEditorProps) {
  const contentSuggestionActions = [
    "Less formal",
    "More formal",
    "Shorter",
    "Funnier",
    "Less braggy",
  ];

  const [currentSideProject, setCurrentSideProject] = useState<SideProject>({
    ...sideProject,
  });

  useEffect(() => {
    setCurrentSideProject(sideProject);
  }, [sideProject]);
  const [contentSuggestionRequestBody, setContentSuggestionRequestBody] =
    useState<ContentSuggestionRequestBody | null>(null);
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [isLoadingContentSuggestion, setIsLoadingContentSuggestion] =
    useState<boolean>(false);
  const [currentImproventSuggestions, setCurrentImproventSuggestions] =
    useState<string>("");

  const [pitchHaveFocus, setPitchHaveFocus] = useState<boolean>(false);

  const onTitleInput = (input: string) => {
    const newSideProject = { ...currentSideProject, title: input };
    onSaveSideProject(newSideProject);
    setCurrentSideProject(newSideProject);
  };

  const onPitchInput = (input: string) => {
    setPitchHaveFocus(false);
    const newSideProject = { ...currentSideProject, pitch: input };
    onSaveSideProject(newSideProject);
    setCurrentSideProject(newSideProject);
  };

  const onGitHubUrlInput = (input: string) => {
    const newSideProject = { ...currentSideProject, githubUrl: input };
    setCurrentSideProject(newSideProject);
  };

  const onWebsiteUrlInput = (input: string) => {
    const newSideProject = { ...currentSideProject, websiteUrl: input };
    setCurrentSideProject(newSideProject);
  };

  let projectSkills = [];
  if (sideProject && sideProject.skillTags) {
    projectSkills = sideProject.skillTags;
  }

  return (
    <div
      className={"project-editor"}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        flex: 1,
      }}
      onMouseEnter={() => setIsMouseHovering(true)}
      onMouseLeave={() => setIsMouseHovering(false)}
    >
      <div className="flex flex-1 flex-row">
        {showSidebar ? (
          <div className="flex-1 flex flex-col items-center align-center">
            <EditorTextButton
              text="Remove"
              handleClick={() => onRemove()}
            ></EditorTextButton>
          </div>
        ) : null}
        <div className="flex flex-[5] flex-col">
          <div style={{ flex: 1, flexDirection: "row" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              {removePadding === true ? null : (
                <div
                  className={
                    "border-[1px] border-op-dark mb-[4px] max-w-[18px] max-h-[18px] min-w-[18px] min-h-[18px] flex justify-center items-center" +
                    (isMouseHovering && isEditable ? " visible" : " invisible")
                  }
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                  }}
                >
                  <Settings size={12} color="#202124" />
                </div>
              )}
              <div className={"flex-1 pl-[8px] mb-[6px]"}>
                {isEditable ? (
                  <EditorTextField
                    disableHoverUnderline={!isEditable}
                    disableEditing={!isEditable}
                    isDarkModeActive={isDarkModeActive}
                    autofocus={false}
                    styles={{ display: "inline", width: "100%" }}
                    placeholder="Project name"
                    onLeaveField={onTitleInput}
                    value={currentSideProject.title}
                    inputStyles={{ fontSize: 18, fontWeight: 600 }}
                    useDynamicWidth
                  />
                ) : (
                  <label style={{ fontSize: 20, fontWeight: "bold" }}>
                    {currentSideProject.title}
                  </label>
                )}
              </div>
            </div>
            {!isEditable ? (
              <label className="pl-[26px] mt-">{workplaceName}</label>
            ) : workplaceName || isEditable ? (
              <div
                style={{
                  height: 50,
                  marginBottom: 0,
                  marginTop: 5,
                  paddingLeft: "26px",
                }}
              >
                <div style={{ minWidth: "70%", float: "left" }}>
                  {isEditable ? (
                    <EditorTextButton
                      disableEditing={!isEditable}
                      isDarkModeActive={isDarkModeActive}
                      text={
                        currentSideProject.isSideProject
                          ? "Side project"
                          : workplaceName !== ""
                            ? workplaceName!
                            : "Select workplace"
                      }
                      handleClick={() =>
                        onShowDialog("workplace", currentSideProject.order)
                      }
                    />
                  ) : (
                    <label>{workplaceName}</label>
                  )}
                </div>
                <div className="flex flex-row justify-end pr-2 float-right">
                  {isEditable ? (
                    isLoadingContentSuggestion ? (
                      <Lottie
                        style={{ width: 30 }}
                        options={{ animationData: animationData }}
                      />
                    ) : null
                  ) : null}
                </div>
              </div>
            ) : null}

            {isEditable ? (
              currentSideProject.pitch !== "" || isEditable ? (
                <div
                  className={
                    "flex flex-row flex-1 pl-[26px]" +
                    (!isEditable ? " mt-[6px]" : "")
                  }
                >
                  <div className={"flex-1"}>
                    <VkTextArea
                      isEditable={isEditable}
                      isDarkModeActive={isDarkModeActive}
                      placeholder="Short description where you can sumarize the project and highlight your contribution, main challanges and present the project outcome."
                      onLeaveField={onPitchInput}
                      value={currentSideProject.pitch}
                      disableHoverUnderline={!isEditable}
                      onFocus={() => setPitchHaveFocus(true)}
                    />
                  </div>
                </div>
              ) : null
            ) : (
              <div className={"flex flex-row flex-1 pl-[26px] mt-[6px]"}>
                <span>{currentSideProject.pitch}</span>
              </div>
            )}

            {projectLinkConfig !== undefined &&
            !projectLinkConfig.displaySkills ? null : (
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: 5 }}
                className={removePadding === true ? "" : "pl-[26px]"}
              >
                {projectSkills.map(
                  (skillOption: ProfileSkillTag, index: number) => {
                    return (
                      <EditorSkillTagButton
                        disableEditing={!isEditable}
                        isDarkModeActive={isDarkModeActive}
                        key={index}
                        text={skillOption.name}
                        profileSkillTag={skillOption}
                        handleClick={() => {
                          onShowDialog("tags", sideProject.order);
                        }}
                      />
                    );
                  },
                )}
              </div>
            )}

            {isEditable ? (
              <div className="pl-[26px]">
                <EditorTextButton
                  disableEditing={!isEditable}
                  isDarkModeActive={isDarkModeActive}
                  text="Add tag"
                  handleClick={() => onShowDialog("tags", sideProject.order)}
                />
              </div>
            ) : null}

            {isEditable || projectPhotos.length > 0 ? (
              <div className="mt-2 pl-[26px]">
                <ProjectPhotosRow
                  profileId={profileId}
                  projectId={currentSideProject.id}
                  projectPhotos={projectPhotos}
                  allowEdit={isEditable}
                  onProjectPhotoSelect={onProjectPhotoSelect}
                  onProjectPhotosUpdate={onProjectPhotosUpdate}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideProjectEditor;
