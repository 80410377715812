import { AnimatePresence, motion } from "framer-motion";
import type { PropsWithChildren } from "react";
import type { LeadRowActiveState } from "../../../../../state/home";

interface Props {
  state: LeadRowActiveState | null;
  className?: string;
}

export const AnimatedPanel = ({ state, children, className }: PropsWithChildren<Props>) => (
  <AnimatePresence>
    {state && (
      <motion.div
        key={state}
        className={className}
        transition={{
          duration: 0.2,
        }}
        initial={{
          height: 0,
          opacity: 0,
        }}
        animate={{
          height: "auto",
          opacity: 1,
        }}
        exit={{
          height: 0,
          opacity: 0,
          transition: {
            duration: 0.15,
          },
        }}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

AnimatedPanel.displayName = "AnimatedPanel";
