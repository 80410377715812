import { useState } from "react";
import LinkButton from "./LinkButton";
import type { SectionProps } from "../types";
import "./components.css";

function SectionHolder(props: React.PropsWithChildren<SectionProps>) {
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false);

  const borderColor = props.isDarkModeActive ? "#e3e3e3" : "gray";

  return (
    <div
      style={{
        marginBottom: "70px",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <h2 className="section-header">{props.title}</h2>
        <div
          style={{
            opacity: isMouseHovering ? "1" : "0",
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "0px",
            left: "0px",
          }}
        ></div>
      </div>

      <div style={{ paddingLeft: "0px" }}>{props.children}</div>
    </div>
  );
}

export default SectionHolder;
