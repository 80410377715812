import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { LeadButton } from "../pages/dashboard/home/components/LeadButton";

export interface IconItem {
  name: string;
  icon: IconDefinition;
}

interface IconGridProps {
  icons: IconItem[];
  onIconClick: (name: string) => void;
  itemsPerRow?: number;
  titles: string[];
}

const IconGrid: React.FC<IconGridProps> = ({
  icons,
  onIconClick,
  titles,
  itemsPerRow = 4,
}) => {
  const rows = Math.ceil(icons.length / itemsPerRow);

  return (
    <div className="flex flex-1 flex-col">
      {[...Array(rows)].map((_, rowIndex) => (
        <div>
          <label className="text-sm text-details-text">
            {titles[rowIndex]}
          </label>

          <div
            key={rowIndex}
            className="flex flex-1 flex-row justify-around p-2"
          >
            {icons
              .slice(rowIndex * itemsPerRow, (rowIndex + 1) * itemsPerRow)
              .map((item) => (
                <LeadButton
                  key={item.name}
                  onClick={() => onIconClick(item.name)}
                >
                  <FontAwesomeIcon icon={item.icon} size="xl" />
                </LeadButton>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default IconGrid;
