import VkTextArea from "./legacy/VkTextArea";
import {
  type ImageUpload,
  type Profile,
  ProfileLinkPersonalInfoConfig,
  type ProfileTag,
} from "../types";
import { useMediaQuery } from "react-responsive";
import EditorTextField from "../components/EditorTextField";
import type { ProfileComponentProps } from "../types";
import ProfilePhoto from "./ProfilePhoto";

interface PersonalDetailsSectonProps extends ProfileComponentProps {
  profile: Profile;
  profilePhotoId?: string;
  isEditable: boolean;
  useProfilePicture: boolean;
  roundedProfilePicture: boolean;
  onShowCropTool: (image: any) => void;
  onProfileUpdate: (updatedProfile: Profile) => void;
  onAddProfileTagClick: () => void;
  onProfileTagsUpdated: (tags: ProfileTag[]) => void;
  hidePitch?: boolean;
  logoAssetUrl?: string | null;
  logoAssetSize?: number | null;
  updatedPhotoId?: string | null;
  isUploadingPhoto: boolean;
  children?: React.ReactNode;
  additionalPitches?: string[];
  removePadding?: boolean;
}

function PersonalDetailsSection({
  profile,
  profilePhotoId,
  isEditable,
  useProfilePicture,
  onShowCropTool,
  onProfileUpdate,
  isDarkModeActive,
  onAddProfileTagClick,
  roundedProfilePicture,
  onProfileTagsUpdated,
  hidePitch,
  logoAssetUrl,
  logoAssetSize,
  children,
  updatedPhotoId,
  isUploadingPhoto,
  additionalPitches,
  removePadding,
}: PersonalDetailsSectonProps) {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

  return (
    <div
      style={{
        position: "relative",
        flex: 1,
        pageBreakAfter: "always",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingLeft: removePadding === true ? "" : "26px",
        marginBottom: "50px",
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex-1 flex flex-row">
          {useProfilePicture ? (
            <ProfilePhoto
              key="profile-photo"
              isLoading={isUploadingPhoto}
              id="profile"
              photoAssetId={
                updatedPhotoId != null ? updatedPhotoId : profilePhotoId
              }
              round={roundedProfilePicture}
              allowEdit={isEditable}
              onImageSelected={(imageUpload: ImageUpload) => {
                const str = imageUpload.fileType + "," + imageUpload.base64File;
                onShowCropTool(str);
              }}
            />
          ) : (
            <span />
          )}

          <div
            className={
              "flex flex-col min-h-[100%] " +
              (useProfilePicture ? "pl-2" : "") +
              (children == null ? " justify-center" : " justify-between")
            }
          >
            <div>
              <EditorTextField
                disableEditing={!isEditable}
                disableHoverUnderline={!isEditable}
                isDarkModeActive={isDarkModeActive}
                placeholder="Your full name"
                onLeaveField={(input) => {
                  const updatedProfile = { ...profile, name: input };
                  onProfileUpdate(updatedProfile);
                }}
                inputStyles={{ fontSize: 18, fontWeight: 600 }}
                value={profile.name}
              />
              {isEditable ? (
                <EditorTextField
                  disableEditing={!isEditable}
                  disableHoverUnderline={!isEditable}
                  isDarkModeActive={isDarkModeActive}
                  placeholder="Title"
                  onLeaveField={(input) => {
                    const updatedProfile = { ...profile, title: input };
                    onProfileUpdate(updatedProfile);
                  }}
                  inputStyles={{ fontSize: 14, fontWeight: "normal" }}
                  value={profile.title}
                  useDynamicWidth
                />
              ) : (
                <span className="text-[14px] font-normal">{profile.title}</span>
              )}
            </div>
            {children ?? null}
          </div>
        </div>
        {logoAssetUrl && !isMobile ? (
          <img
            src={
              logoAssetUrl.length > 100
                ? logoAssetUrl
                : "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                  logoAssetUrl
            }
            className={`object-scale-contain`}
            style={{ maxHeight: logoAssetSize ?? 40 }}
          />
        ) : null}
      </div>
      <div style={{ marginTop: 15, minWidth: "100%" }}>
        {hidePitch == false && !(!isEditable && profile.pitch === "") ? (
          isEditable ? (
            <VkTextArea
              disableHoverUnderline={!isEditable}
              isEditable={isEditable}
              isDarkModeActive={isDarkModeActive}
              placeholder="Describe yourself, your profession, your passion or anything you would like to share with potential colleagues"
              onLeaveField={(input: string) => {
                const updatedProfile = { ...profile, pitch: input };
                onProfileUpdate(updatedProfile);
              }}
              value={profile.pitch}
              onTextAreaFocus={() => {}}
              inputType="pitch"
            />
          ) : (
            <span className="">{profile.pitch}</span>
          )
        ) : null}
        {additionalPitches !== null
          ? additionalPitches?.map((p, i) => {
              return (
                <div className="mt-4">
                  <span>{p}</span>;
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default PersonalDetailsSection;
