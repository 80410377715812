import { faComment } from "@fortawesome/free-regular-svg-icons";
import { Input, trim, InputRef, Button, LoadingSpinner } from "../components";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  getOrganization,
  updateOrganization,
  updateOrganizationLogo,
} from "../requests";
import {
  ImageUpload,
  Organization,
  OrganizationOverview,
  UpdatedOrganization,
} from "../types";
import { useParams } from "react-router";
import { getImageUrl } from "../utils/image";
import { Image } from "../components/image";

interface Props {
  organizationId?: string;
}
function TempOrgSettings() {
  const { organizationId } = useParams();

  const [organizationOverview, setOrganizationOverview] =
    useState<OrganizationOverview | null>(null);
  const [updatedOrganization, setUpdatedOrganization] =
    useState<UpdatedOrganization>({
      name: "",
      website: "",
      phoneNumber: "",
      email: "",
    });

  // Add local state for input values
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    website: "",
  });

  const nameInputRef = useRef<InputRef>(null);

  const orgLogoImage = (orgLogoId: string) =>
    orgLogoId ? getImageUrl(orgLogoId) : undefined;

  useEffect(() => {
    if (!organizationId) return;
    getOrganization(organizationId).then(
      (organizationOverview: OrganizationOverview) => {
        const newOrgData = {
          name: organizationOverview.name,
          website: organizationOverview.website ?? "",
          email: organizationOverview.email ?? "",
          phoneNumber: organizationOverview.phoneNumber ?? "",
        };
        setUpdatedOrganization(newOrgData);
        // Initialize input values with the fetched data
        setInputValues(newOrgData);
        setOrganizationOverview(organizationOverview);
      },
    );
  }, [organizationId]);

  const handleUpdateName = useCallback(
    (value: string) => {
      if (!organizationId) return;
      const org = { ...updatedOrganization, name: value };
      setUpdatedOrganization(org);
      updateOrganization(organizationId, org);
    },
    [updatedOrganization],
  ); // Add updatedOrganization to dependencies

  const handleUpdateEmail = useCallback(
    (value: string) => {
      if (!organizationId) return;
      const org = { ...updatedOrganization, email: value };
      setUpdatedOrganization(org);
      updateOrganization(organizationId, org);
    },
    [updatedOrganization],
  ); // Add updatedOrganization to dependencies

  const handleUpdatePhoneNumber = useCallback(
    (value: string) => {
      if (!organizationId) return;
      const org = { ...updatedOrganization, phoneNumber: value };
      setUpdatedOrganization(org);
      updateOrganization(organizationId, org);
    },
    [updatedOrganization],
  ); // Add updatedOrganization to dependencies

  const handleUpdateWebsite = useCallback(
    (value: string) => {
      if (!organizationId) return;
      const org = { ...updatedOrganization, website: value };
      setUpdatedOrganization(org);
      updateOrganization(organizationId, org);
    },
    [updatedOrganization],
  ); // Add updatedOrganization to dependencies

  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const handleImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    isMainLogo: boolean,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64ImageString = reader.result as string;

        const formatInfo = base64ImageString.split(",")[0];
        const base64String = base64ImageString.split(",")[1];
        const format = formatInfo.split(";")[0].split(":")[1];

        const imageUpload: ImageUpload = {
          fileType: format,
          base64File: base64String,
        };

        if (!organizationOverview) return;

        updateOrganizationLogo(
          organizationOverview.id,
          imageUpload,
          isMainLogo,
        ).then((newOrganizationLogoId: string) => {
          if (isMainLogo) {
            setOrganizationOverview((prev) =>
              prev ? { ...prev, logoId: newOrganizationLogoId } : null,
            );
          } else {
            setOrganizationOverview((prev) =>
              prev ? { ...prev, iconLogoId: newOrganizationLogoId } : null,
            );
          }
        });
      };
      reader.readAsDataURL(file);
    }
  };

  if (!organizationOverview) return <div />;

  return (
    <div>
      <Input
        leftIcon={faComment}
        label="Organization name"
        transformValue={trim}
        value={inputValues.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInputValues((prev) => ({ ...prev, name: e.target.value }));
        }}
        rightButton={{
          label: "Save",
          type: "submit",
          onClick: () => {
            handleUpdateName(inputValues.name);
            // Update the main state after saving
            setUpdatedOrganization((prev) => ({
              ...prev,
              name: inputValues.name,
            }));
          },
        }}
      />
      <Input
        leftIcon={faComment}
        label="Organization contact email"
        value={inputValues.email}
        transformValue={trim}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInputValues((prev) => ({ ...prev, email: e.target.value }));
        }}
        rightButton={{
          label: "Save",
          type: "submit",
          onClick: () => {
            handleUpdateEmail(inputValues.email);
            setUpdatedOrganization((prev) => ({
              ...prev,
              email: inputValues.email,
            }));
          },
        }}
      />
      <Input
        leftIcon={faComment}
        label="Organization contact phone number"
        value={inputValues.phoneNumber}
        transformValue={trim}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInputValues((prev) => ({ ...prev, phoneNumber: e.target.value }));
        }}
        rightButton={{
          label: "Save",
          type: "submit",
          onClick: () => {
            handleUpdatePhoneNumber(inputValues.phoneNumber);
            setUpdatedOrganization((prev) => ({
              ...prev,
              phoneNumber: inputValues.phoneNumber,
            }));
          },
        }}
      />
      <Input
        leftIcon={faComment}
        label="Organization website"
        value={inputValues.website}
        transformValue={trim}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInputValues((prev) => ({ ...prev, website: e.target.value }));
        }}
        rightButton={{
          label: "Save",
          type: "submit",
          onClick: () => {
            handleUpdateWebsite(inputValues.website);
            setUpdatedOrganization((prev) => ({
              ...prev,
              website: inputValues.website,
            }));
          },
        }}
      />
      <div>
        <span>Main logo</span>
        <Image
          src={orgLogoImage(organizationOverview.logoId)}
          alt="Profile"
          className="w-60 h-60 rounded-lg"
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageUpload(e, true)}
          style={{}}
        />
      </div>

      <div>
        <span>Icon logo</span>
        <Image
          src={
            organizationOverview.iconLogoId
              ? orgLogoImage(organizationOverview.iconLogoId!)
              : ""
          }
          alt="Profile"
          className="w-60 h-60 rounded-lg"
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageUpload(e, false)}
          style={{}}
        />
      </div>
    </div>
  );
}

export default TempOrgSettings;
