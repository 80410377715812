import { useState, useRef, useEffect, useCallback } from "react";
import "../../index.css";
import Cropper from "react-easy-crop";
import VkImageCropperControls from "./VkImageCropperControls";
const VkImageCropper = ({
  originalImage,
  onImageCropperCancelled,
  onImageCropperComplete,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log(croppedArea, croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        originalImage,
        croppedAreaPixels,
        0,
      );
      setCroppedImage(croppedImage);
      onImageCropperComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, 0]);

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    // As Base64 string
    return canvas.toDataURL("image/png");

    // As a blob
    /*
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
    */
  }

  if (croppedImage !== null) {
    return (
      <div id="vk-crop-tool-container">
        <div id="vk-crop-tool-preview">
          <img src={croppedImage} alt="" id="vk-cropped-image-preview" />
        </div>

        <VkImageCropperControls
          zoom={zoom}
          onZoomChange={(zoom) => setZoom(zoom)}
          onCancelButtonClick={onImageCropperCancelled}
          onContinueButtonClick={() => onImageCropperComplete(croppedImage)}
          onBackButtonClick={() => setCroppedImage(null)}
        />
      </div>
    );
  }

  return (
    <div id="vk-crop-tool-container">
      <div id="vk-crop-tool">
        <Cropper
          style={{ containerStyle: { backgroundColor: "#000" } }}
          image={originalImage}
          crop={crop}
          zoom={zoom}
          aspect={4 / 4}
          cropShape="round"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <VkImageCropperControls
        zoom={zoom}
        onZoomChange={(zoom) => setZoom(zoom)}
        onCancelButtonClick={onImageCropperCancelled}
        onContinueButtonClick={showCroppedImage}
        onBackButtonClick={null}
      />
    </div>
  );
};

export default VkImageCropper;
