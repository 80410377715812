import type { Profile, ProfileLinkConfig } from "../../../../../client";
import { BulletSectionTitle } from "../../../../../components";
import { Workplace } from "./Workplace";

interface Props {
  profileData?: Profile;
  linkConfigData: ProfileLinkConfig;
  handleUpdateLink: (data: Partial<ProfileLinkConfig>) => void;
}

export const LinkCreatorWork = ({ profileData, linkConfigData, handleUpdateLink }: Props) => {
  const handleCheckedChange = (checked: boolean, workplaceId: string) => {
    const currentIds = new Set(
      linkConfigData.profileLinkWorkplaceIds ??
        profileData?.workplaces.map((workplace) => workplace.id)
    );

    if (checked) {
      currentIds.add(workplaceId);
    } else {
      currentIds.delete(workplaceId);
    }

    handleUpdateLink({ profileLinkWorkplaceIds: Array.from(currentIds) });
  };

  return (
    <div className="flex flex-col gap-4">
      <BulletSectionTitle>Work experience</BulletSectionTitle>
      <div className="flex flex-col gap-6">
        {profileData?.workplaces.map((workplace) => (
          <Workplace
            key={workplace.id}
            checked={
              !linkConfigData.profileLinkWorkplaceIds ||
              linkConfigData.profileLinkWorkplaceIds?.includes(workplace.id)
            }
            onCheckedChange={(checked) => handleCheckedChange(checked, workplace.id)}
            name={workplace.name}
            skillTags={workplace.skillTags}
            title={workplace.role}
            description={workplace.description}
            startDate={workplace.startDate}
            endDate={workplace.endDate}
            showSkills={linkConfigData.workplaceLinkConfig?.displaySkills}
            showDescription={linkConfigData.workplaceLinkConfig?.displayDescription}
          />
        ))}
      </div>
    </div>
  );
};
