import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import type { PropsWithChildren } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHandle,
  DrawerTitle,
} from "../../../../components/drawer";
import { homeState } from "../../../../state/home";
import { LeadDrawerMenu } from "./LeadDrawerMenu";
import { LeadPanelContent } from "./LeadPanelContent";

export const MobileMenuDrawer = ({ children }: PropsWithChildren) => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const { selectedLead } = useSnapshot(homeState);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const handleClose = () => {
    homeState.selectedLead = null;
  };

  return (
    <Drawer
      direction="bottom"
      open={!!selectedLead?.state && isMobile}
      onClose={handleClose}
      shouldScaleBackground={false}
    >
      {children}

      <DrawerContent className="h-full max-h-[90vh] gap-2" direction="bottom" onClose={handleClose}>
        <DrawerHandle />
        <VisuallyHidden.Root>
          <DrawerTitle>Lead</DrawerTitle>
          <DrawerDescription>Information about the lead</DrawerDescription>
        </VisuallyHidden.Root>
        {selectedLead && (
          <div className="p-4 overflow-y-auto">
            <LeadDrawerMenu
              organizationId={organizationId}
              leadId={selectedLead.leadId ?? undefined}
              state={selectedLead.state}
            />
            <LeadPanelContent
              organizationId={organizationId}
              leadId={selectedLead.leadId ?? undefined}
              state={selectedLead.state}
            />
          </div>
        )}
      </DrawerContent>
    </Drawer>
  );
};
