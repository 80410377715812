import withCustomStyling from "./HOC/withCustomStyling";

interface Props {
  title: string;
  pdfMode?: boolean;
}

function EditorTitle({ title, pdfMode }: Props) {
  return (
    <div className="flex flex-row items-center" style={{ marginLeft: "14px" }}>
      <div
        style={{
          width: 4,
          height: 4,
          background: "#202124",
          marginTop: pdfMode ? 15 : 0,
        }}
      />
      <label className="ml-2 font-bold">{title}</label>
    </div>
  );
}
export default withCustomStyling(EditorTitle);
