import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";
import { cn } from "../utils/cn";

export const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer group h-8 w-8 shrink-0 rounded-xl border-2 border-red-2 bg-red-1 p-1.5 disabled:cursor-not-allowed disabled:opacity-50 active:scale-90",
      "data-[state=checked]:bg-red-2 text-red-2 data-[state=checked]:text-red-3",
      "transition-all duration-150",
      className
    )}
    {...props}
  >
    <div
      className={cn(
        "flex items-center justify-center rounded-md w-full h-full transition-colors duration-200",
        "bg-red-2 group-hover:bg-red-3 group-data-[state=checked]:bg-red-3"
      )}
    >
      <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-white")}>
        <FontAwesomeIcon icon={faCheck} className="h-3 w-3" width={12} height={12} />
      </CheckboxPrimitive.Indicator>
    </div>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;
