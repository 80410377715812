import type { ButtonHTMLAttributes } from "react";
import { cn } from "../../utils/cn";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary";
}

export const Button = ({ variant = "secondary", className, children, ...props }: Props) => (
  <button
    type="button"
    className={cn(
      "rounded-xl px-4 py-2 text-sm font-medium hover:font-bold active:scale-90 transition-all duration-100",
      "disabled:opacity-50 disabled:active:scale-100 disabled:hover:font-semibold",
      variant === "primary" && "bg-red-1 hover:bg-red-2 border border-red-2 text-red-3",
      variant === "secondary" && "bg-gray-1 hover:bg-gray-3",
      className
    )}
    {...props}
  >
    {children}
  </button>
);
