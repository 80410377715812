import type { Options } from "@hey-api/client-fetch";
import { type UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  Client,
  PostV1OrganisationsByOrganizationIdClientsData,
  PostV1OrganisationsByOrganizationIdClientsError,
  PostV1OrganisationsByOrganizationIdClientsResponse,
} from "../../../client";
import { postV1OrganisationsByOrganizationIdClientsMutation } from "../../../client/@tanstack/react-query.gen";
import { jsonParse } from "../../utils/parse";
import { getClientsOptions } from "./get-clients";

const mutationKey = ["createClient"];

const getClientsQueryKey = (organizationID: string) => getClientsOptions(organizationID).queryKey;

export const useCreateClient = (
  options: UseMutationOptions<
    PostV1OrganisationsByOrganizationIdClientsResponse,
    PostV1OrganisationsByOrganizationIdClientsError,
    Options<PostV1OrganisationsByOrganizationIdClientsData>
  > = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...postV1OrganisationsByOrganizationIdClientsMutation(),
    mutationKey,
    ...options,
    onSuccess: (client, vars, context) => {
      queryClient.setQueryData(getClientsQueryKey(vars.path.organizationID), (oldData) => {
        const newClient = jsonParse<Client>(client);
        if (!oldData) return [newClient];
        return [...oldData, newClient];
      });

      options?.onSuccess?.(client, vars, context);
    },
    onSettled: (data, error, vars, context) => {
      queryClient.invalidateQueries({
        queryKey: getClientsQueryKey(vars.path.organizationID),
      });

      options?.onSettled?.(data, error, vars, context);
    },
  });
};
