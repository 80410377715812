import { Popover, PopoverTrigger } from "../../../../components";
import { LeadButton, type LeadButtonProps } from "./LeadButton";
import { TemperatureIcon } from "./icon";
import { TemperatureMenu } from "./menu";

interface Props extends LeadButtonProps {
  priority: number;
  onPriorityChange: (priority: number) => void;
}

export const LeadTemperatureButton = ({ priority, onPriorityChange, ...props }: Props) => (
  <Popover>
    <PopoverTrigger asChild>
      <LeadButton {...props}>
        <TemperatureIcon priority={priority} />
      </LeadButton>
    </PopoverTrigger>
    <TemperatureMenu priority={priority} onPriorityChange={onPriorityChange} />
  </Popover>
);
