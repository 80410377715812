import { useCallback } from "react";
import type { Lead } from "../../../../client";
import { useGetClient, useGetLead, useUpdateLead } from "../../../../services";
import { type LeadRowActiveState, homeState } from "../../../../state/home";
import { LeadAssignmentButton } from "./LeadAssignmentButton";
import { LeadClientButton } from "./LeadClientButton";
import { LeadEventsButton } from "./LeadEventsButton";
import { LeadMemberButton } from "./LeadMemberButton";
import { LeadMenuButton } from "./LeadMenuButton";
import { LeadQRButton } from "./LeadQRButton";
import { LeadTemperatureButton } from "./LeadTemperatureButton";

interface Props {
  organizationId: string;
  leadId?: string;
  state: LeadRowActiveState;
}

export const LeadDrawerMenu = ({ organizationId, leadId, state }: Props) => {
  const updateLead = useUpdateLead();

  const { data: lead } = useGetLead(
    { leadID: leadId ?? "", organizationID: organizationId },
    { enabled: !!leadId }
  );

  const { data: client } = useGetClient({
    clientId: updateLead.variables?.body.clientId ?? lead?.clientId ?? "",
    organizationID: organizationId,
  });

  const onUpdateLead = useCallback(
    (payload: Partial<Lead>) => {
      if (!lead) return;
      updateLead.mutate({
        path: { organizationID: organizationId, leadID: lead.id ?? "" },
        body: { ...lead, ...payload },
      });
    },
    [updateLead, organizationId, lead]
  );

  const onButtonClick = (state: LeadRowActiveState) => {
    homeState.selectedLead = {
      leadId: leadId ?? null,
      state,
    };
  };

  const leadProfileId = updateLead.variables?.body.profileId ?? lead?.profileId ?? undefined;

  return (
    <div id="lead-drawer-menu" className="flex flex-row gap-2 -mx-4 px-4 overflow-x-scroll">
      <LeadClientButton
        active={state === "client"}
        onClick={() => {
          onButtonClick("client");
        }}
        client={client}
        contactPersonEmail={lead?.contactPersonEmail}
        className="px-4"
        id="lead-drawer-menu-client"
      />
      <LeadAssignmentButton
        active={state === "assignment"}
        onClick={() => {
          onButtonClick("assignment");
        }}
        title={lead?.title || lead?.assignmentInfo?.title}
        className="px-4"
        id="lead-drawer-menu-assignment"
      />
      <LeadMemberButton
        active={state === "member"}
        onClick={() => {
          onButtonClick("member");
        }}
        profileId={leadProfileId}
        organizationId={organizationId}
        className="px-4"
        id="lead-drawer-menu-member"
      />
      <LeadQRButton
        active={state === "qr"}
        onClick={() => {
          onButtonClick("qr");
        }}
        profileLinkId={lead?.profileLinkId}
        className="px-4"
        id="lead-drawer-menu-qr"
      />

      <LeadEventsButton
        active={state === "events"}
        onClick={() => {
          onButtonClick("events");

          if (lead && (lead.numberOfUnreadEvents ?? 0) > 0)
            onUpdateLead?.({ numberOfUnreadEvents: 0 });
        }}
        numberOfUnreadEvents={lead?.numberOfUnreadEvents ?? 0}
        className="px-4"
        id="lead-drawer-menu-events"
      />
      <LeadTemperatureButton
        priority={lead?.priority ?? 0}
        onPriorityChange={(priority) => onUpdateLead?.({ priority })}
        className="px-4"
        id="lead-drawer-menu-temperature"
      />
      <LeadMenuButton
        className="px-4"
        onArchive={() => onUpdateLead?.({ status: "archived" })}
        id="lead-drawer-menu-menu"
      />
    </div>
  );
};
