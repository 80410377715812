import { useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import { useSnapshot } from "valtio";
import {
  type LeadRowActiveState,
  getNextLeadRowActiveState,
  homeState,
} from "../../../../state/home";

interface Props {
  leadId: string | null;
}

export const useToggleActiveState = ({ leadId }: Props) => {
  const { selectedLead } = useSnapshot(homeState);

  const activeState = selectedLead?.leadId === leadId ? (selectedLead?.state ?? false) : false;

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const scrollToLead = useCallback(
    (leadId: string | null) => {
      if (!isMobile) {
        setTimeout(() => {
          const leadRow = document.querySelector(`[data-lead-id="${leadId ?? "new"}"]`);
          if (!leadRow) return;

          const navigationBar = document.getElementById("navigation-bar");
          if (!navigationBar) return;

          const y =
            leadRow.getBoundingClientRect().top + window.scrollY - navigationBar.clientHeight - 16;

          window.scrollTo({ top: y, behavior: "smooth" });
        }, 250);
      }
    },
    [isMobile]
  );

  const toggleActiveState = useCallback(
    (state: LeadRowActiveState) => {
      const isDifferentLead = selectedLead?.leadId !== leadId;
      const nextState = getNextLeadRowActiveState(activeState, state);

      homeState.selectedLead = {
        leadId,
        state: nextState,
      };

      if (nextState && isDifferentLead) scrollToLead(leadId);
    },
    [activeState, leadId, selectedLead, scrollToLead]
  );

  return { activeState, toggleActiveState, scrollToLead };
};
