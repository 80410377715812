import { faBuilding, faUser } from "@fortawesome/free-regular-svg-icons";
import type { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";
import { useAnimate } from "../../hooks";
import { tabsState } from "../../state/tabs";
import { MenuSection } from "../menu-section";
import { cn } from "../utils/cn";
import { ClientButtonLink } from "./ClientButtonLink";
import { IconButtonLink } from "./IconButtonLink";
import { ImageButtonLink } from "./ImageButtonLink";
import { MemberButtonLink } from "./MemberButtonLink";
import { getBaseUrl } from "./utils";

interface Props {
  organizationId: string;
  className?: string;
  organizationLogo?: string;
}

const NavigationMobileBar = ({
  organizationId,
  className,
  organizationLogo,
}: Props) => {
  const { pathname } = useLocation();
  const tabsSnap = useSnapshot(tabsState);
  const baseUrl = getBaseUrl(organizationId);

  return (
    <div
      className={cn(
        "flex flex-row items-center flex-grow flex-shrink min-w-0 overflow-x-scroll",
        className
      )}
    >
      {pathname.startsWith(`${baseUrl}/members`) ? (
        <MobileMenuSection>
          <IconButtonLink href={`${baseUrl}/members`} icon={faUser} />
          {tabsSnap.members.length ? <MenuSection.Separator /> : null}
          {tabsSnap.members.map((memberId) => (
            <MemberButtonLink key={memberId} organizationId={organizationId} memberId={memberId} />
          ))}
        </MobileMenuSection>
      ) : pathname.startsWith(`${baseUrl}/clients`) ? (
        <MobileMenuSection>
          <IconButtonLink href={`${baseUrl}/clients`} icon={faBuilding} />
          {tabsSnap.clients.length ? <MenuSection.Separator /> : null}
          {tabsSnap.clients.map((clientId) => (
            <ClientButtonLink key={clientId} organizationId={organizationId} clientId={clientId} />
          ))}
        </MobileMenuSection>
      ) : pathname.startsWith(baseUrl) ? (
        <ImageButtonLink
          href={baseUrl}
          imageUrl={organizationLogo}
          alt="VOCS Studio"
        />
      ) : null}
    </div>
  );
};

NavigationMobileBar.displayName = "NavigationMobileBar";

const MobileMenuSection = ({ children }: PropsWithChildren) => {
  const [parent] = useAnimate();

  return (
    <ul ref={parent} className="flex flex-row items-stretch flex-shrink gap-2 transition-all">
      {children}
    </ul>
  );
};

export { NavigationMobileBar };
