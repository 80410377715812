import { Code } from "react-feather";
import type { ProfileComponentProps, ProfileSkillTag } from "../../types";
import "../components.css";
import { iconForName } from "../TechIcons";
import DefaultSkillIcon from "../../assets/defaultSkillIcon.png";
import { useState } from "react";
import { getSkillInfo } from "../../requests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { IconItem } from "../IconGrid";

import {
  faRainbow,
  faCubes,
  faPenNib,
  faWandMagicSparkles,
  faOtter,
  faLaptopCode,
  faMagnifyingGlassChart,
  faChartPie,
  faCodeBranch,
  faSitemap,
  faLayerGroup,
  faPeopleArrows,
  faClipboardQuestion,
  faPersonCircleQuestion,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import {
  faComments,
  faHandshake,
  faObjectUngroup,
} from "@fortawesome/free-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
interface Props extends ProfileComponentProps {
  text: string;
  profileSkillTag?: ProfileSkillTag;
  handleClick: () => void;
  disableEditing?: boolean;
  keepLeftPadding?: boolean;
  pdfMode?: boolean;
}

const EditorSkillTagButton = (props: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [skillDescription, setSkillDescription] = useState<string | null>(null);

  const icons: IconItem[] = [
    { name: "rainbow", icon: faRainbow },
    { name: "cubes", icon: faCubes },
    { name: "pen-nib", icon: faPenNib },
    { name: "wand-magic-sparkles", icon: faWandMagicSparkles },
    { name: "object-ungroup", icon: faObjectUngroup },
    { name: "otter", icon: faOtter },
    { name: "laptop-code", icon: faLaptopCode },
    { name: "magnifying-glass-chart", icon: faMagnifyingGlassChart },
    { name: "chart-pie", icon: faChartPie },
    { name: "code-branch", icon: faCodeBranch },
    { name: "site-map", icon: faSitemap },
    { name: "layer-group", icon: faLayerGroup },
    { name: "people-arrows", icon: faPeopleArrows },
    { name: "comments", icon: faComments },
    { name: "clipboard-question", icon: faClipboardQuestion },
    { name: "person-circle-question", icon: faPersonCircleQuestion },
    { name: "users-viewfinder", icon: faUsersViewfinder },
    { name: "handshake", icon: faHandshake },
  ];

  const getIconByName = (name: string): IconDefinition | undefined => {
    const iconItem = icons.find((item) => item.name === name);
    return iconItem ? iconItem.icon : undefined;
  };

  const renderIcon = () => {
    if (!props.profileSkillTag) {
      return iconForName(props.text);
    }

    if (
      props.profileSkillTag.customIconName &&
      props.profileSkillTag.customIconName !== ""
    ) {
      const customIcon = getIconByName(props.profileSkillTag.customIconName);
      return customIcon ? (
        <FontAwesomeIcon icon={customIcon} size="lg" />
      ) : null;
    }

    return iconForName(props.profileSkillTag.name);
  };

  var classNames = "editor-skill-tag-button";

  if (props.keepLeftPadding) {
    var classNames = "editor-skill-tag-button-left-padding";
  }

  if (props.isDarkModeActive) {
    classNames += " editor-skill-tag-button-light light-color";
  }

  const handleMouseEnter = async () => {
    setIsHovering(true);

    if (!skillDescription) {
      try {
        getSkillInfo(props.text).then((skillInfo) => {
          setSkillDescription(skillInfo);
        });
      } catch (error) {
        setSkillDescription(
          "We don´t have any more information about this skill at the moment but we are working on it!",
        );
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className={classNames}
      onClick={props.handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          marginTop: "2px",
          width: props.text === "" ? "0px" : "20px",
          marginRight: props.text === "" ? "0px" : "5px",
        }}
      >
        {renderIcon() ??
          (props.text !== "" ? <img src={DefaultSkillIcon} /> : null)}
      </div>
      <label style={props.pdfMode ? { marginBottom: 15 } : { marginBottom: 0 }}>
        {props.text}
      </label>
      {isHovering && props.disableEditing ? (
        <div
          className={`
          absolute top-full left-0 z-50
          bg-op-dark
          text-white
          border border-gray-200 dark:border-gray-700
          rounded-md shadow-md
          p-2 mt-1
          max-w-xs text-sm
          whitespace-normal
        `}
        >
          {skillDescription}
        </div>
      ) : null}
    </div>
  );
};

export default EditorSkillTagButton;
