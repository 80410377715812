import type { Options } from "@hey-api/client-fetch";
import { type UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
  type GetV1QueryClientInfoData,
  type GetV1QueryClientInfoError,
  type GetV1QueryClientInfoResponse,
  getV1QueryClientInfo,
} from "../../../client";

const mutationKey = ["requestClientInfo"];

export const useRequestClientInfo = (
  opts?: UseMutationOptions<
    GetV1QueryClientInfoResponse,
    GetV1QueryClientInfoError,
    Options<GetV1QueryClientInfoData>
  >
) => {
  return useMutation({
    ...opts,
    mutationFn: async (options) => {
      const { data } = await getV1QueryClientInfo({
        ...options,
        throwOnError: true,
      });
      return data;
    },
    mutationKey,
  });
};
