import { useParams } from "react-router-dom";
import { Notice, Section, SectionTitle } from "../../../components";
import { useAnimate } from "../../../hooks";
import { useGetLeads } from "../../../services";
import { ExistingLeadRow } from "./components/ExistingLeadRow";
import { LeadRow } from "./components/LeadRow";
import { MobileMenuDrawer } from "./components/MobileMenuDrawer";
import { LinkCreatorDrawer } from "./components/link-creator/LinkCreatorDrawer";

const Component = () => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const { data } = useGetLeads(organizationId);

  const [parent] = useAnimate();

  if (!data) return <p>No data</p>;

  return (
    <LinkCreatorDrawer>
      <MobileMenuDrawer>
        <Section
          title="Leads"
          className="lg:overflow-x-scroll"
          containerComponent="ul"
          ref={parent}
        >
          {data.map((lead) => (
            <ExistingLeadRow key={lead.id} organizationId={organizationId} lead={lead} />
          ))}

          <SectionTitle>Create new Lead & Profile Version</SectionTitle>

          <LeadRow organizationId={organizationId} />
        </Section>

        <Notice
          className="bg-transparent"
          label="Contracts and Archived leads can be found on client page"
        />
      </MobileMenuDrawer>
    </LinkCreatorDrawer>
  );
};

Component.displayName = "DashboardHome";

export default Component;
