import React, { useEffect, useState } from "react";
import {
  type ImageUpload,
  ProfileComponentProps,
  type ProjectPhoto,
} from "../types";
import "./components.css";
import animationData from "../assets/spinner-animation.json";
import Lottie from "react-lottie";
import DefaultIcon from "../assets/op_o.png";
import ProjectPhotoItem from "./ProjectPhotoItem";
import { createProjectPhoto, removeProjectPhoto } from "../requests";
import {
  ArrowLeft,
  ArrowRight,
  Maximize,
  Minimize,
  Trash,
  X,
} from "react-feather";
import Button, { ButtonStyleType } from "./atoms/Button";

interface Props {
  profileId: string;
  assetId: string;
  isEditable: boolean;
  projectPhotos: ProjectPhoto[];
  onClose: () => void;
  onToggleFullScreen: (toggleFullSceenOn: boolean) => void;
  onProjectPhotosUpdate: (updatedProjectPhotos: ProjectPhoto[]) => void;
}

export default function ProjectPhotoViewer(props: Props) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [projectPhotos, setProjectPhotos] = useState<ProjectPhoto[]>([]);

  const moveRight = () => {
    if (currentIndex < projectPhotos.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const moveLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(projectPhotos.length - 1);
    }
  };

  useEffect(() => {
    updateSelectedPhoto(props.assetId);
  }, []);

  const updateSelectedPhoto = (assetId: string) => {
    const profilePhoto = props.projectPhotos.filter(
      (p) => p.assetId === assetId,
    )[0];

    const filteredProjectPhotos = props.projectPhotos.filter(
      (p) => p.projectId === profilePhoto.projectId,
    );

    for (var i = 0; i < filteredProjectPhotos.length; i++) {
      if (filteredProjectPhotos[i].assetId === assetId) {
        setCurrentIndex(i);
      }
    }

    setProjectPhotos(filteredProjectPhotos);
  };

  if (projectPhotos.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col flex-1 p-2">
      <div className="flex justify-between mb-4">
        <div className="flex flex-row">
          <div className="cursor-pointer mr-2">
            <X onClick={() => props.onClose()} />
          </div>
          <div className="cursor-pointer">
            {fullScreen ? (
              <Minimize
                onClick={() => {
                  setFullScreen(false);
                  props.onToggleFullScreen(false);
                }}
              />
            ) : (
              <Maximize
                onClick={() => {
                  setFullScreen(true);
                  props.onToggleFullScreen(true);
                }}
              />
            )}
          </div>
        </div>

        {props.isEditable ? (
          <div
            className="border-[1px] border-transparent hover:border-red-400 p-2"
            onClick={() => {
              const projectId = projectPhotos[currentIndex].projectId;
              removeProjectPhoto(
                props.profileId,
                projectId,
                projectPhotos[currentIndex].assetId,
              ).then((updatedProjectPhotos: ProjectPhoto[]) => {
                if (currentIndex > 0) {
                  setCurrentIndex(currentIndex - 1);
                } else {
                  setCurrentIndex(0);
                }

                const filteredProjectPhotos = updatedProjectPhotos.filter(
                  (p) => p.projectId === projectId,
                );
                setProjectPhotos(filteredProjectPhotos);
                props.onProjectPhotosUpdate(updatedProjectPhotos);
              });
            }}
          >
            <Trash color="red" />
          </div>
        ) : null}
      </div>

      <div className="flex flex-row flex-1">
        <div className="min-w-[20px] max-w-[20px] flex items-center">
          <ArrowLeft onClick={() => moveLeft()} />
        </div>

        <div className="flex justify-center flex-1">
          <img
            className={
              "object-contain " +
              (fullScreen
                ? "max-w-[300px] md:max-w-[900px] max-h-[600px]"
                : "max-w-[200px] md:max-w-[500px] max-h-[500px]")
            }
            src={
              "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
              projectPhotos[currentIndex].assetId
            }
          />
        </div>

        <div className="min-w-[20px] flex items-center">
          <ArrowRight onClick={() => moveRight()} />
        </div>
      </div>

      <div className="flex flex-row justify-center mt-4 overflow-scroll">
        {projectPhotos.map((projectPhoto, index) => {
          return (
            <div
              className={
                "mr-4" +
                (index === currentIndex ? " border-4 border-op-blue" : "")
              }
            >
              <ProjectPhotoItem
                key={index}
                id={projectPhoto.assetId}
                isLoading={false}
                allowEdit={true}
                round={false}
                onImageSelected={(imageUpload: ImageUpload) => {}}
                photoAssetId={projectPhoto.assetId}
                onProjectPhotoSelect={(assetId: string) =>
                  updateSelectedPhoto(assetId)
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
