import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import type { ProfileLinkConfig, UserProfile } from "../../../../../client";
import { Avatar, Checkbox, Input } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";
import { getImageUrl } from "../../../../../utils/image";
import { ProjectSettingsContainer } from "./ProjectSettingsContainer";

interface Props {
  linkConfigData: ProfileLinkConfig;
  disabled: boolean;
  handleUpdateLink: (data: Partial<ProfileLinkConfig>) => void;
  profile?: UserProfile;
}

export const LinkCreatorPersonalBlock = ({
  linkConfigData,
  disabled,
  handleUpdateLink,
  profile,
}: Props) => {
  const [customPitch, setCustomPitch] = useState(linkConfigData?.customPitch ?? "");
  const debounced = useDebouncedCallback<(val: string) => void>((value) => {
    handleUpdateLink({
      customPitch: value,
    });
  }, 500);

  return (
    <div className="flex flex-col grow gap-6">
      <div className="flex flex-row items-center gap-4">
        <Checkbox
          checked={linkConfigData?.profileLinkPersonalInfoConfig?.showPersonalInfo}
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            handleUpdateLink({
              profileLinkPersonalInfoConfig: {
                ...linkConfigData?.profileLinkPersonalInfoConfig,
                showPersonalInfo: checked,
              },
            });
          }}
        />

        <ProjectSettingsContainer
          className="flex flex-row items-center"
          enabled={linkConfigData?.profileLinkPersonalInfoConfig?.showPersonalInfo ?? false}
        >
          <Avatar
            alt={
              typeof profile?.profileData.name === "string" ? profile.profileData.name : "Profile"
            }
            imageUrl={profile?.profilePhotoId ? getImageUrl(profile.profilePhotoId) : undefined}
            className={cn(
              "w-20 h-20 transition-all duration-300",
              linkConfigData?.profileLinkPersonalInfoConfig?.roundMode ? "rounded-[5rem]" : "",
              linkConfigData?.useProfilePicture
                ? "scale-100 opacity-100 mr-4"
                : "scale-0 opacity-0 w-0 mr-0"
            )}
          />

          <div className="flex flex-col">
            <p className="text-sm font-semibold">
              {typeof profile?.profileData.name === "string" ? profile.profileData.name : "Profile"}
            </p>
            {typeof profile?.profileData.title === "string" && (
              <p className="text-sm">{profile.profileData.title}</p>
            )}
          </div>
        </ProjectSettingsContainer>
      </div>

      <div className="flex flex-row items-start gap-4">
        <Checkbox
          checked={linkConfigData?.profileLinkPersonalInfoConfig?.showPitch ?? false}
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            handleUpdateLink({
              profileLinkPersonalInfoConfig: {
                ...linkConfigData?.profileLinkPersonalInfoConfig,
                showPitch: checked,
              },
            });
          }}
        />
        <ProjectSettingsContainer
          className="flex flex-row items-center"
          enabled={linkConfigData?.profileLinkPersonalInfoConfig?.showPitch ?? false}
        >
          <p className="text-xs">
            {typeof profile?.profileData.pitch === "string" ? profile.profileData.pitch : ""}
          </p>
        </ProjectSettingsContainer>
      </div>

      <div className="flex flex-row items-start gap-4">
        <Checkbox
          checked={linkConfigData?.showCustomPitch ?? false}
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            handleUpdateLink({
              showCustomPitch: checked,
            });
          }}
        />
        <ProjectSettingsContainer
          className="flex flex-row items-center grow"
          enabled={linkConfigData?.showCustomPitch ?? false}
        >
          <Input
            className="w-full bg-gray-1 focus-within:bg-gray-1"
            inputClassName="text-xs"
            placeholder="Add a custom pitch"
            value={customPitch}
            onChange={(e) => {
              const { value } = e.target;
              setCustomPitch(value);
              debounced(value);
            }}
          />
        </ProjectSettingsContainer>
      </div>
    </div>
  );
};
