import type { PropsWithChildren } from "react";
import { cn } from "../../../../../components/utils/cn";

type Props = PropsWithChildren<{ enabled: boolean; className?: string }>;

export const ProjectSettingsContainer = ({ children, enabled, className }: Props) => (
  <div
    className={cn(
      "transition-opacity duration-200",
      enabled ? "opacity-100" : "opacity-40",
      className
    )}
  >
    {children}
  </div>
);
