import type { ClientWithContact, Lead } from "../../../../client";
import { cn } from "../../../../components/utils/cn";
import { useToggleActiveState } from "../utils/use-toggle-active-state";
import { LeadAssignmentButton } from "./LeadAssignmentButton";
import { LeadClientButton } from "./LeadClientButton";
import { LeadEventsButton } from "./LeadEventsButton";
import { LeadMemberButton } from "./LeadMemberButton";
import { LeadMenuButton } from "./LeadMenuButton";
import { LeadPanelContent } from "./LeadPanelContent";
import { LeadQRButton } from "./LeadQRButton";
import { LeadTemperatureButton } from "./LeadTemperatureButton";

interface Props {
  organizationId: string;
  client?: ClientWithContact;
  lead?: Lead;
  onUpdateLead?: (payload: Partial<Lead>) => void;
}

export const LeadRow = ({ organizationId, client, lead, onUpdateLead }: Props) => {
  const { activeState, toggleActiveState } = useToggleActiveState({ leadId: lead?.id ?? null });

  const leadNumberOfUnreadEvents = lead?.numberOfUnreadEvents ?? 0;

  return (
    <li
      data-lead-id={lead?.id ?? "new"}
      className={cn(
        "flex flex-col p-2 rounded-2xl bg-white transition-colors duration-300 overflow-hidden",
        activeState && "lg:bg-gray-1"
      )}
    >
      <div
        className={cn(
          "hidden w-full lg:flex flex-row gap-4 items-center justify-between overflow-hidden md:overflow-auto",
          !lead && "lg:grid grid-cols-4"
        )}
      >
        <LeadClientButton
          active={activeState === "client"}
          onClick={() => toggleActiveState("client")}
          client={client}
          contactPersonEmail={lead?.contactPersonEmail}
        />

        <LeadAssignmentButton
          active={activeState === "assignment"}
          onClick={() => toggleActiveState("assignment")}
          title={lead?.title || lead?.assignmentInfo?.title}
        />

        <LeadMemberButton
          active={activeState === "member"}
          onClick={() => toggleActiveState("member")}
          profileId={lead?.profileId ?? undefined}
          organizationId={organizationId}
        />

        <LeadQRButton
          active={activeState === "qr"}
          onClick={() => toggleActiveState("qr")}
          profileLinkId={lead?.profileLinkId}
        />

        {lead && (
          <div className="flex flex-row gap-1 items-center self-stretch">
            <LeadEventsButton
              active={activeState === "events"}
              onClick={() => {
                toggleActiveState("events");
                // Reset the number of unread events to 0
                if (leadNumberOfUnreadEvents > 0) onUpdateLead?.({ numberOfUnreadEvents: 0 });
              }}
              numberOfUnreadEvents={leadNumberOfUnreadEvents}
            />
            <LeadTemperatureButton
              priority={lead?.priority ?? 1}
              onPriorityChange={(priority) => onUpdateLead?.({ priority })}
              className="hidden lg:flex"
            />
            <LeadMenuButton
              className="px-4"
              onArchive={() => onUpdateLead?.({ status: "archived" })}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col lg:hidden">
        <LeadClientButton
          client={client}
          contactPersonEmail={lead?.contactPersonEmail}
          onClick={() => toggleActiveState("client")}
        />
      </div>

      <LeadPanelContent
        state={activeState}
        organizationId={organizationId}
        className="hidden lg:block"
        divider
        leadId={lead?.id}
      />
    </li>
  );
};

LeadRow.displayName = "LeadRow";
