interface Props {
  title: string;
  name?: string;
  startDate?: string;
  endDate?: string;
}

export const WorkplaceHeader = ({ title, name, startDate, endDate }: Props) => (
  <div className="flex flex-col text-sm">
    <p className="font-semibold">{title}</p>
    <div className="flex flex-row justify-between">
      {name && <p className="text-gray-6">{name}</p>}
      {startDate && (
        <p className="text-gray-6">
          {endDate ? `${startDate} - ${endDate}` : `${startDate} - current`}
        </p>
      )}
    </div>
  </div>
);
