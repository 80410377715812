import { type ComponentProps, useMemo } from "react";
import type { SkillTag } from "../../../../../client";
import { Checkbox } from "../../../../../components";
import { iconForName } from "../../../../../components/TechIcons";
import { cn } from "../../../../../components/utils/cn";
import { isDefined } from "../../../../../utils/guards";
import { ProjectSettingsContainer } from "./ProjectSettingsContainer";
import { WorkplaceHeader } from "./WorkplaceHeader";

interface Props extends ComponentProps<typeof WorkplaceHeader> {
  description?: string;
  skillTags: SkillTag[];
  showSkills?: boolean;
  showDescription?: boolean;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
}

export const Workplace = ({
  description,
  skillTags,
  showSkills,
  showDescription,
  checked,
  onCheckedChange,
  ...props
}: Props) => {
  const skills = useMemo(
    () =>
      skillTags
        ?.map((skill) =>
          iconForName(skill.name) ? { icon: iconForName(skill.name), name: skill.name } : undefined
        )
        .filter(isDefined),
    [skillTags]
  );

  return (
    <div className="flex flex-row items-start gap-4 w-full">
      <Checkbox checked={checked} onCheckedChange={onCheckedChange} />
      <ProjectSettingsContainer className="flex flex-col w-full min-w-0" enabled={checked ?? true}>
        <WorkplaceHeader {...props} />
        {description && (
          <p
            className={cn(
              "text-xs pt-4",
              "transition-all duration-300 overflow-hidden",
              showDescription ? "opacity-100 pt-4" : "max-h-0 opacity-0 pt-0"
            )}
          >
            {description}
          </p>
        )}
        <div
          className={cn(
            "flex flex-row gap-4 pt-4 overflow-y-auto max-w-full",
            "transition-all duration-300",
            showSkills ? "h-12 opacity-100 pt-4" : "h-0 opacity-0 scale-y-0 pt-0"
          )}
        >
          {skills?.map(({ icon, name }) => (
            <div key={name} className="flex flex-row items-center gap-3">
              <div className="w-6 h-6">{icon}</div>
              <p className="text-xs">{name}</p>
            </div>
          ))}
        </div>
      </ProjectSettingsContainer>
    </div>
  );
};
