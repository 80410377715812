import { useEffect, useState } from "react";
import SideProjectEditor from "./SideProjectEditor";
import SectionHolder from "./SectionHolder";

import type {
  SideProject,
  ProfileComponentProps,
  SideProjectsSession,
  Workplace,
  ProjectPhoto,
  WorkplaceItem,
  ProjectsLinkConfig,
} from "../types";
import AddNewItemBlock from "./AddNewItemBlock";
import "./components.css";
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from "@hello-pangea/dnd";
import { AlignJustify, Plus } from "react-feather";
import ContentToggle from "./ContentToggle";
import EditorSkillTagButton from "./atoms/EditorSkillTagButton";
import WorkplaceEditor from "./WorkplaceEditor";
import EditorTextIconButton from "./EditorTextIconButton";

interface SideProjectSectionProps extends ProfileComponentProps {
  profileId: string;
  isEditable: boolean;
  sideProjectSection: SideProjectsSession;
  sectionCount: number;
  onAddSideProject: (sideProject: SideProject) => void;
  onUpdateSideProject: (sideProject: SideProject) => void;
  onRemoveSideProject: (sideProject: SideProject) => void;
  onDeleteSectionClick: () => void;
  onShowDialog: (dialogType: string, projectOrder: number) => void;
  currentlySelectedWorkplace?: Workplace | undefined | null;
  configurable: boolean;
  onProjectOrderChange: (projects: SideProject[]) => void;
  onProjectToggle?: (project: SideProject, toggleState: boolean) => void;
  toggledProjectIds?: string[];
  projectPhotos: ProjectPhoto[];
  onProjectPhotoSelect?: (assetId: string) => void;
  onProjectPhotosUpdate?: (updatedProjectPhotos: ProjectPhoto[]) => void;
  workplaces: WorkplaceItem[];
  projectLinkConfing?: ProjectsLinkConfig;
}

function SideProjectSection({
  profileId,
  isEditable,
  sideProjectSection,
  sectionCount,
  onAddSideProject,
  onUpdateSideProject,
  onRemoveSideProject,
  onDeleteSectionClick,
  onShowDialog,
  isDarkModeActive,
  currentlySelectedWorkplace,
  configurable,
  onProjectOrderChange,
  onProjectToggle,
  toggledProjectIds,
  projectPhotos,
  onProjectPhotoSelect,
  onProjectPhotosUpdate,
  workplaces,
  projectLinkConfing,
}: SideProjectSectionProps) {
  const [isAddNewSkillProjectSelected, setIsAddNewSkillProjectSelected] =
    useState<boolean>(false);
  const [contentImprovementIsActive, setContentImprovementIsActive] =
    useState<boolean>(false);
  const sideProjects = sideProjectSection.side_projects;
  const [currentProfileSideProjects, setCurrentProfileSideProjects] = useState<
    SideProject[]
  >([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [workplaceSideProject, setWorkplaceSideProject] = useState<SideProject>(
    {
      id: "",
      title: "",
      pitch: "",
      order: -1,
      githubUrl: "",
      websiteUrl: "",
      workplace: null,
      skillTags: [],
      isSideProject: false,
    },
  );

  // a little function to help us with reordering the result
  function reorder(list: SideProject[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  useEffect(() => {
    setCurrentProfileSideProjects(sideProjects);
  }, []);

  function onDragEnd(result: DropResult) {
    // dropped outside the list
    setIsDragging(false);
    if (!result.destination) {
      return;
    }

    var currrentSideProjects = [...sideProjects];

    const items = reorder(
      currrentSideProjects,
      result.source.index,
      result.destination.index,
    );

    var updatedSideProjects = items.map((item, index) => {
      item.order = index;
      return item;
    });

    onProjectOrderChange(updatedSideProjects);
  }

  const getWorkplaceName = (sideProject: SideProject) => {
    if (!workplaces) {
      return "";
    }
    if (
      workplaces.filter((wp) => wp.id === sideProject.workplaceId).length > 0
    ) {
      return workplaces.filter((wp) => wp.id === sideProject.workplaceId)[0]
        .name;
    }
    return "";
  };

  if (configurable) {
    return (
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeCapture={() => {
          setIsDragging(true);
        }}
      >
        <Droppable droppableId="sdsd">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sideProjects.map((sideProject, index) => {
                return (
                  <Draggable
                    key={sideProject.title}
                    draggableId={sideProject.title}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <ContentToggle
                          key={index}
                          toggled={
                            (toggledProjectIds?.includes(sideProject.id) ||
                              false) &&
                            !isDragging
                          }
                          dragHandle={() => {
                            return (
                              <div
                                {...provided.dragHandleProps}
                                className="flex flex-row w-full ml-5 mt-2"
                              >
                                <AlignJustify color="grey">
                                  drag here
                                </AlignJustify>
                              </div>
                            );
                          }}
                          renderToggleOnContent={() => {
                            return (
                              <SideProjectEditor
                                profileId={profileId}
                                isEditable={false}
                                isDarkModeActive={false}
                                isBeingEdited={true}
                                isNew={false}
                                sideProject={sideProject}
                                onSaveSideProject={(savedSideProject) => {}}
                                onCancelEdit={() => {}}
                                onRemove={() => {}}
                                onShowDialog={(dialogType: string) => {}}
                                currentlySelectedWorkplace={null}
                                isLastInOrder={false}
                                projectPhotos={[]}
                                projectLinkConfig={projectLinkConfing}
                                removePadding={true}
                              />
                            );
                          }}
                          renderToggleOffContent={() => {
                            return (
                              <div>
                                <h4
                                  style={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                  }}
                                >
                                  {sideProject.title}
                                </h4>
                                <label>
                                  {sideProject.workplaceId
                                    ? getWorkplaceName(sideProject)
                                    : ""}
                                </label>
                                {!projectLinkConfing?.displaySkills ? null : (
                                  <div className="flex flex-1">
                                    {sideProject.skillTags.map(
                                      (skillTag, index) => {
                                        return (
                                          <EditorSkillTagButton
                                            disableEditing={true}
                                            isDarkModeActive={false}
                                            key={index}
                                            text={skillTag.name}
                                            profileSkillTag={skillTag}
                                            handleClick={() => {}}
                                          />
                                        );
                                      },
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                          onToggleOn={() => {
                            if (onProjectToggle) {
                              onProjectToggle(sideProject, true);
                            }
                          }}
                          onToggleOff={() => {
                            if (onProjectToggle) {
                              onProjectToggle(sideProject, false);
                            }
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  if (!isEditable) {
    return (
      <SectionHolder
        isDarkModeActive={isDarkModeActive}
        onDeleteSectionClick={onDeleteSectionClick}
        title={sideProjectSection.title}
        order={sideProjectSection.order}
        totalSectionCount={sectionCount}
        isEditable={isEditable}
      >
        {sideProjects.map((sideProject, index) => {
          return (
            <SideProjectEditor
              profileId={profileId}
              isEditable={isEditable}
              isDarkModeActive={isDarkModeActive}
              key={index}
              isBeingEdited={true}
              isNew={false}
              sideProject={sideProject}
              onSaveSideProject={(savedSideProject) => {
                onUpdateSideProject(savedSideProject);
              }}
              onCancelEdit={() => {}}
              onRemove={() => onRemoveSideProject(sideProject)}
              onShowDialog={(dialogType: string) =>
                onShowDialog(dialogType, sideProject.order)
              }
              currentlySelectedWorkplace={currentlySelectedWorkplace}
              isLastInOrder={index === sideProjects.length - 1}
              projectPhotos={projectPhotos.filter(
                (p) => p.projectId === sideProject.id,
              )}
              onProjectPhotoSelect={onProjectPhotoSelect}
              projectLinkConfig={projectLinkConfing}
              workplaceName={getWorkplaceName(sideProject)}
            />
          );
        })}
      </SectionHolder>
    );
  }

  return (
    <SectionHolder
      isDarkModeActive={isDarkModeActive}
      onDeleteSectionClick={onDeleteSectionClick}
      title={sideProjectSection.title}
      order={sideProjectSection.order}
      totalSectionCount={sectionCount}
      isEditable={isEditable}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sdsd">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sideProjects.map((sideProject, index) => {
                return (
                  <Draggable
                    key={sideProject.id}
                    draggableId={sideProject.id}
                    index={index}
                    isDragDisabled={true}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SideProjectEditor
                          profileId={profileId}
                          isEditable={isEditable}
                          isDarkModeActive={isDarkModeActive}
                          key={index}
                          isBeingEdited={true}
                          isNew={false}
                          sideProject={sideProject}
                          onSaveSideProject={(savedSideProject) => {
                            onUpdateSideProject(savedSideProject);
                          }}
                          onCancelEdit={() => {}}
                          onRemove={() => onRemoveSideProject(sideProject)}
                          onShowDialog={(dialogType: string) =>
                            onShowDialog(dialogType, sideProject.order)
                          }
                          currentlySelectedWorkplace={
                            currentlySelectedWorkplace
                          }
                          isLastInOrder={index === sideProjects.length - 1}
                          onContentImprovementStarted={() => {
                            setContentImprovementIsActive(true);
                          }}
                          onContentImprovementEnded={() => {
                            setContentImprovementIsActive(false);
                          }}
                          projectPhotos={projectPhotos.filter(
                            (p) => p.projectId === sideProject.id,
                          )}
                          onProjectPhotoSelect={onProjectPhotoSelect}
                          onProjectPhotosUpdate={onProjectPhotosUpdate}
                          workplaceName={getWorkplaceName(sideProject)}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isEditable ? (
        <div style={{ marginTop: sideProjects.length > 0 ? "50px" : "0px" }}>
          {isAddNewSkillProjectSelected ? (
            <SideProjectEditor
              key="new-project-editor"
              profileId={profileId}
              isEditable={!isEditable}
              isDarkModeActive={isDarkModeActive}
              sideProject={null}
              onSaveSideProject={(sideProject) => {
                setIsAddNewSkillProjectSelected(false);
                onAddSideProject(sideProject);
              }}
              isBeingEdited={false}
              isNew={true}
              onRemove={() => {}}
              onCancelEdit={() => setIsAddNewSkillProjectSelected(false)}
              onShowDialog={(dialogType: string) =>
                onShowDialog(dialogType, -1)
              }
              currentlySelectedWorkplace={currentlySelectedWorkplace}
              isLastInOrder={false}
              projectPhotos={[]}
            />
          ) : (
            <EditorTextIconButton
              text="Add new project"
              handleClick={() => {
                setIsAddNewSkillProjectSelected(false);
                const newSideProject: SideProject = {
                  id: "",
                  title: "",
                  pitch: "",
                  order: -1,
                  githubUrl: "",
                  websiteUrl: "",
                  workplace: null,
                  skillTags: [],
                  isSideProject: false,
                };
                onAddSideProject(newSideProject);
              }}
              iconChild={
                <div className="bg-op-dark">
                  <Plus color="#fefefe" />
                </div>
              }
            />
          )}
        </div>
      ) : null}
    </SectionHolder>
  );
}

export default SideProjectSection;
